import React, { Component } from 'react'
import apicaller from '../../util/ApiCaller';
import swal from 'sweetalert';

export class ForgotPassword extends Component {

    constructor() {
        super();
        this.state = {
            user: {},
        }
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const userId = urlParams.get('userId')
        this.setState({
            userId
        })
    }

    handleOnChangeFields = (userField, value) => {
        let user = this.state.user;
        user[userField] = value
        this.setState({
            user,
        })
    }

    handleOnSubmit = () => {
        let user = this.state.user;
        if (user.password != user.repeat_password) {
            swal('Confirm password does not matched with password');
        }
        else {
            let data = {
                password: user.password
            }
            apicaller('post', `verify/forgot-password/user/${this.state.userId}/update`, data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.user) {
                            this.props.history.push('/login');
                        }
                        else {
                            swal('Interal Error','some internal issue please try later', 'error');
                        }
                    }
                })
        }
    }


    render() {
        return (
            <div>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {/* Theme tittle */}
                <title>Ek Rupay</title>
                {/* Theme style CSS */}
                <link href="css/style.css" rel="stylesheet" />
                <link href="./vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
                <link rel="stylesheet" href="./vendor/chartist/css/chartist.min.css" />
                {/* Vectormap */}
                <link href="./vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
                <link href="./vendor/bootstrap-select/dist/css/bootstrap-select.min.css" rel="stylesheet" />
                <link href="./css/style.css" rel="stylesheet" />
                <link href="https://cdn.lineicons.com/2.0/LineIcons.css" rel="stylesheet" />
                <link href="./vendor/owl-carousel/owl.carousel.css" rel="stylesheet" />
                <div className="authincation mt-6 h-100">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <h4 className="text-center mb-4 text-white">Forgot Password</h4>
                                                <div className="form-group">
                                                    <label className="mb-1 text-white"><strong>New Password</strong></label>
                                                    <input type="password" className="form-control" onChange={(e) => this.handleOnChangeFields("password", e.target.value)} placeholder="Enter New Password" name="" required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="mb-1 text-white"><strong>Confirm Password</strong></label>
                                                    <input type="password" className="form-control" onChange={(e) => this.handleOnChangeFields("repeat_password", e.target.value)} placeholder="Confirm Password" name="password" required />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <button type="submit" onClick={this.handleOnSubmit} className="btn bg-white text-primary btn-block">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword
