import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import apicaller from '../../util/ApiCaller';
import _ from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import swal from 'sweetalert';

class CustomerProfile extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            updateUser: {},
            displayKycBlock: false,
            isLightBoxOpen: false,
            selectedImage: '',
            isUpdatingKyc: false,
            isUpdatingUser: false,
            isUpdatingPassword: false,
        }
    }

    componentDidMount() {
        let userId = this.props.match.params.id;
        this.fetchUserProfileDetails(userId);
    }

    getImage = async (fieldName, fileKey) => {
        await apicaller('get', `get/file?fileKey=${fileKey}`)
            .then(res => {
                if (res.status == 200) {
                    let user = this.state.user;
                    let buf = Buffer.from(res.data);
                    let base64 = buf.toString('base64');
                    let final_image = `data:image/jpeg;base64,${base64}`
                    user[fieldName] = final_image
                    this.setState({ user, updateUser: user })
                }
                else {
                    swal('some internal issue', '', 'warning');
                }
            })
    }

    downloadUserImages = async () => {
        if (this.state.user.pan_image) {
            await this.getImage("pan_image", this.state.user.pan_image)
        }
        if (this.state.user.pan_image) {
            await this.getImage("aadhar_front_image", this.state.user.aadhar_front_image)
        }
        if (this.state.user.pan_image) {
            await this.getImage("aadhar_back_image", this.state.user.aadhar_back_image)
        }
    }

    fetchUserProfileDetails = (userId) => {
        apicaller('get', `user/${userId}/profile`)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.user) {
                        this.setState({
                            user: res.data.data.user
                        }, () => {
                            this.downloadUserImages();
                        })
                    }
                    else {
                        swal('some internal issue', '', 'warning');
                    }
                }
                else {
                    swal('some internal issue', '', 'warning');
                }
            })
            .catch(err => {
                swal('Error', err, 'error');
            })
    }
    executeScroll = () => this.myRef.scrollIntoView()
    toggleKycBlock = () => {
        this.setState({
            displayKycBlock: !this.state.displayKycBlock
        }, () => {
            if (this.state.displayKycBlock) {
                this.executeScroll();
            }
        })
    }

    handleOnChangeFields = (userField, value) => {
        let updateUser = this.state.updateUser;
        updateUser[userField] = value
        this.setState({
            updateUser,
        })
    }

    handleOnUploadFiles = (userField, files) => {
        let value;
        for (var i in files) {
            if (i != "length" && i != "item") {
                document.querySelector(`#${userField}`).textContent = files[i].name;
                var FileSize = files[i].size / 1024 / 1024; // in MB
                if (FileSize > 3) {
                    swal('Alert', 'File size exceeds 3 MB', 'warning');
                }
                value = files[i];
            }
        }
        let updateUser = this.state.updateUser;
        updateUser[userField] = value
        this.setState({
            updateUser,
        })
    }

    validateUserFields(input) {
        let output = {};
        output.isValid = true;
        output.message = "";
        if (!input.pan_number || input.pan_number == "") {
            output.isValid = false;
            output.message = "Please enter pan number";
            return output;
        }
        if (!input.pan_image || !_.isEmpty(input.pan_image)) {
            output.isValid = false;
            output.message = "Pan card image is required";
            return output;
        }
        if (!input.pan_image || (input.pan_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Pan card image should be less than 3MB";
            return output;
        }
        if (!input.aadhar_number || input.aadhar_number == "") {
            output.isValid = false;
            output.message = "Please enter aadhar number";
            return output;
        }
        if (!input.aadhar_front_image || !_.isEmpty(input.aadhar_front_image)) {
            output.isValid = false;
            output.message = "Aadhar front image image is required";
            return output;
        }
        if (!input.aadhar_front_image || (input.aadhar_front_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Aadhar front image should be less than 3MB";
            return output;
        }
        if (!input.aadhar_back_image || !_.isEmpty(input.aadhar_back_image)) {
            output.isValid = false;
            output.message = "Aadhar back image image is required";
            return output;
        }
        if (!input.aadhar_back_image || (input.aadhar_back_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Aadhar back image should be less than 3MB";
            return output;
        }
        return output;
    }

    handleOnCompleteKycUpdate = () => {
        let updateUser = this.state.updateUser;
        let validation = this.validateUserFields(updateUser);
        if (validation.isValid) {
            this.setState({
                isUpdatingKyc: true
            })
            let data = new FormData();
            data.append('pan_number', updateUser.pan_number);
            data.append('pan_image', updateUser.pan_image);
            data.append('aadhar_number', updateUser.aadhar_number);
            data.append('aadhar_front_image', updateUser.aadhar_front_image);
            data.append('aadhar_back_image', updateUser.aadhar_back_image);
            data.append('isKycProcessed', true);
            data.append('phone', this.state.user.phone);
            apicaller('post', 'user/customer/complete-kyc', data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.user) {
                            this.setState({
                                user: res.data.data.user
                            })
                            this.toggleKycBlock()
                        }
                    }
                    this.setState({
                        isUpdatingKyc: false
                    })
                })
        }
        else {
            swal(validation.message, '', 'warning');
        }
    }

    validateUpdatePassword = (input) => {
        let output = {};
        output.isValid = true;
        output.message = "";
        const rpass = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,16}$/
        if (!input.current_password || input.current_password == "") {
            output.isValid = false;
            output.message = "Please enter current password";
            return output;
        }
        if (!input.new_password || input.new_password == "" || !rpass.test(input.new_password)) {
            output.isValid = false;
            output.message = "Please check for minimum conditions and enter a password.";
            return output;
        }
        if (input.new_password != input.confirm_new_password) {
            output.isValid = false;
            output.message = "Password confirmation does not matched";
            return output;
        }
        return output;
    }

    handleOnUpdatePassword = () => {
        let data = {};
        data.current_password = this.state.updateUser.current_password;
        data.new_password = this.state.updateUser.new_password;
        data.confirm_new_password = this.state.updateUser.confirm_new_password;
        let validation = this.validateUpdatePassword(data);
        if (validation.isValid) {
            this.setState({
                isUpdatingPassword: true
            })
            apicaller('post', `user/${this.state.user._id}/password/update`, data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.user) {
                            swal('password updated successfully', '', 'success');
                        }
                        else {
                            swal(res.data.data.message, '', 'warning');
                        }
                        document.getElementById('password-model-close-button').click();
                    }
                    else {
                        swal('some internal issue');
                    }
                    this.setState({
                        isUpdatingPassword: false
                    })
                })
                .catch(err => {
                    swal('Error', err, 'error');
                    this.setState({
                        isUpdatingPassword: false
                    })
                })
        }
        else {
            swal(validation.message, '', 'warning')
        }
    }

    handleOnUpdateProfile = () => {
        this.setState({
            isUpdatingUser: true
        })
        let data = {};
        data.first_name = this.state.updateUser.first_name || this.state.user.first_name;
        data.last_name = this.state.updateUser.last_name || this.state.user.last_name;
        apicaller('post', `user/${this.state.user._id}/profile/update`, data)
            .then(res => {
                if (res.status == 200) {
                    document.getElementById('update-model-close-button').click();
                    swal("user updated successfully", '', 'success');
                }
                else {
                    swal('some internal issue', '', 'warning');
                }
                this.setState({
                    isUpdatingUser: false
                })
            })
            .catch(err => {
                swal('some internal issue', '', 'warning');
                this.setState({
                    isUpdatingUser: false
                })
            })
    }

    handleShowPasswordConditions = () => {
        let ele = document.getElementById('password');
        ele.style.display = 'block'
    }
    handleHidePasswordConditions = () => {
        let ele = document.getElementById('password');
        ele.style.display = 'none'
    }

    render() {
        return (
            <div>
                <title>Profile</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Profile"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card profile-card">
                                        <div className="card-header flex-wrap border-0 pb-0">
                                            <h3 className="fs-24 text-black font-w600 mr-auto mb-2 pr-3">Profile Info<br />
                                                {this.state.user.isKycCompleted ?
                                                    <span className="text-success" style={{ fontSize: 15 }}><i class="zmdi zmdi-star-circle"></i> KYC Completed</span>
                                                    :
                                                    this.state.user.isKycProcessed ?
                                                        <span className="text-warning" style={{ fontSize: 15 }}> KYC under review</span>
                                                        :
                                                        <span className="text-danger" style={{ fontSize: 15 }}> Non KYC User</span>
                                                }</h3>
                                            {this.state.user.isKycProcessed || this.state.displayKycBlock ? null :
                                                <button className="btn btn-primary btn-rounded mr-3 mb-2" onClick={this.toggleKycBlock}>Complete KYC</button>
                                            }
                                            <button className="btn btn-primary btn-rounded mr-3 mb-2" data-toggle="modal" data-target="#editProfileModal">Edit Profile</button>
                                            <button className="btn btn-primary btn-rounded mb-2" data-toggle="modal" data-target="#changePasswordModal">Change Password</button>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <div className="row">
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">First Name</h5>
                                                        <span>{this.state.user.first_name || ""}</span>
                                                    </div>
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">Last Name</h5>
                                                        <span>{this.state.user.last_name || ""}</span>
                                                    </div>
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">Email</h5>
                                                        <span>{this.state.user.email || ""}</span>
                                                    </div>
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">Phone Number</h5>
                                                        <span>{this.state.user.phone || ""}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#updateKyc" id="scrollToKycSection" className="d-none"></a>
                            {this.state.displayKycBlock ?
                                <div className="row" ref={(ref) => this.myRef = ref} style={{
                                    scrollMarginTop: 150
                                }}>
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-header flex-wrap border-0 pb-0">
                                                <h3 className="fs-24 text-black font-w600 mr-auto mb-2 pr-3">Update KYC</h3>
                                            </div>
                                            <div className="card-body">
                                                <form onSubmit={(e) => { e.preventDefault(); this.handleOnCompleteKycUpdate() }}>
                                                    <div className="mb-3">
                                                        <div className="row">
                                                            <div className="col-lg-6 mb-2">
                                                                <div className="form-group">
                                                                    <label className="text-label">PAN Card Number*</label>
                                                                    <input type="text" name="panNumber" className="form-control" onChange={(e) => this.handleOnChangeFields("pan_number", e.target.value)} placeholder="Enter PAN Card Number" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <label className="text-label">Upload PAN Card*</label>
                                                                <div className="input-group">
                                                                    <div className="custom-file">
                                                                        <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("pan_image", e.target.files)} required />
                                                                        <label className="custom-file-label" id="pan_image">Choose file</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <div className="form-group">
                                                                    <label className="text-label">Aadhaar Card Number*</label>
                                                                    <input type="text" name="aadhaarNumber" className="form-control" onChange={(e) => this.handleOnChangeFields("aadhar_number", e.target.value)} placeholder="Enter Aadhaar Card Number" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <label className="text-label">Upload Aadhaar Card Front Image*</label>
                                                                <div className="input-group">
                                                                    <div className="custom-file">
                                                                        <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("aadhar_front_image", e.target.files)} required />
                                                                        <label className="custom-file-label" id="aadhar_front_image">Choose file</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <label className="text-label">Upload Aadhaar Card Back Image*</label>
                                                                <div className="input-group">
                                                                    <div className="custom-file">
                                                                        <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("aadhar_back_image", e.target.files)} required />
                                                                        <label className="custom-file-label" id="aadhar_back_image">Choose file</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mb-2 text-center">
                                                                <button type="button" className="btn btn-primary btn-block" disabled={this.state.isUpdatingKyc}>{this.state.isUpdatingKyc ? "submitting" : "Submit"}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {this.state.user.isKycCompleted ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-header flex-wrap border-0 pb-0">
                                                <h3 className="fs-24 text-black font-w600 mr-auto mb-2 pr-3">KYC Details</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">PAN Card Number</h5>
                                                        <span>{this.state.user.pan_number}</span>
                                                    </div>
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">PAN Card</h5>
                                                        <img src={this.state.user.pan_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.pan_image })} alt="" className="mt-2 mb-2 w-50" />
                                                    </div>
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">Aadhaar Card Number</h5>
                                                        <span>{this.state.user.aadhar_number}</span>
                                                    </div>
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">Aadhaar Card Front Image</h5>
                                                        <img src={this.state.user.aadhar_front_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.aadhar_front_image })} alt="" className="mt-2 mb-2 w-50" />
                                                    </div>
                                                    <div className="col-xl-6 col-sm-6 mb-2">
                                                        <h5 className="f-w-500">Aadhaar Card Back Image</h5>
                                                        <img src={this.state.user.aadhar_back_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.aadhar_back_image })} alt="" className="mt-2 mb-2 w-50" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
                {/* Change Password Modal */}
                <div className="modal fade" id="changePasswordModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Change Password</div>
                            <div className="modal-body">
                                <form onSubmit={(e) => { e.preventDefault(); this.handleOnUpdatePassword() }}>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Current Password</strong></label>
                                                <input type="password" name="current_password" value={this.state.updateUser.current_password} onChange={(e) => this.handleOnChangeFields("current_password", e.target.value)} className="form-control" placeholder="Enter Current Password" required />
                                            </div>
                                            <div className="form-group">
                                                <label className="text-label"><strong>New Password</strong></label>
                                                <input onFocus={this.handleShowPasswordConditions} value={this.state.updateUser.new_password} onBlur={this.handleHidePasswordConditions} type="password" name="new_password" onChange={(e) => this.handleOnChangeFields("new_password", e.target.value)} className="form-control" placeholder="Enter New Password" required />
                                                <div id="password" style={{ display: 'none' }}>
                                                    <small className="text-info">Password should contain</small><br />
                                                    <small>Atleast one Uppercase letter</small><br />
                                                    <small>Atleast one Special character letter</small><br />
                                                    <small>Atleast one Number letter</small><br />
                                                    <small>Minimum length of 8 and Maximum of 16</small><br />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-label"><strong>Confirm new Password</strong></label>
                                                <input type="password" name="confirm_new_password" value={this.state.updateUser.confirm_new_password} onChange={(e) => this.handleOnChangeFields("confirm_new_password", e.target.value)} className="form-control" placeholder="Enter New Password" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="reset" className="btn btn-primary btn-block" disabled={this.state.isUpdatingPassword}>{this.state.isUpdatingPassword ? 'Updating' : 'Update'}</button>
                                        <button type="button" className="d-none" id="password-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLightBoxOpen && (
                        <Lightbox
                            mainSrc={this.state.selectedImage}
                            onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
                        />
                    )
                }
                {/* Edit Profile Modal */}
                <div className="modal fade" id="editProfileModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Edit Profile</div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>First Name</strong></label>
                                            <input type="text" className="form-control" placeholder="Enter First Name" onChange={(e) => this.handleOnChangeFields("first_name", e.target.value)} value={this.state.updateUser.first_name} required />
                                        </div>
                                        <div className="form-group">
                                            <label className="text-label"><strong>Last Name</strong></label>
                                            <input type="text" className="form-control" placeholder="Enter Last Name" onChange={(e) => this.handleOnChangeFields("last_name", e.target.value)} value={this.state.updateUser.last_name} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary btn-block" onClick={this.handleOnUpdateProfile} disabled={this.state.isUpdatingUser}>{this.state.isUpdatingUser ? "Updating" : "Update"}</button>
                                    <button type="button" className="d-none" id="update-model-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default CustomerProfile;