import React, { Component } from 'react';

class TermsAndConditions extends Component {
    render() {
        return (
            <div>
                <h1 className="text-center">EKRUPAY</h1>
                <div className="container" style={{ borderTopWidth: 1, borderColor: '#4620A0', borderStyle: 'solid' }}>
                </div>
                <div className="mt-3 container">
                    <h2>Terms and Conditions</h2>
                    <h4>General</h4>
                    <p>
                        The EKRUPAY website ("Website") and related services (together with the Website, the "Service") are Owned by EKRUPAY. ("EKRUPAY", "us" or "we"). Access and use of the Service is subject to the following Terms and Conditions of Service ("Terms and Conditions"). By accessing or using any part of the Service, you represent that you have read, understood, and agree to be bound by these Terms and Conditions including any future modifications. EKRUPAY may amend, update or change these Terms and Conditions. If we do this, we will post a notice that we have made changes to these Terms and Conditions on the Website for at least 7 days after the changes are posted and will indicate at the bottom of the Terms and Conditions the date these terms were last revised. Any revisions to these Terms and Conditions will become effective the earlier of (i) the end of such 7-day period or (ii) the first time you access or use the Service after such changes. If you do not agree to abide by these Terms and Conditions, you are not authorized to use, access or participate in the Service.
                    </p>
                    <h4>Description of Website and Service</h4>
                    <p>The Service allows users to (i) prepare for any examination online. Users interested in learning or practicing are presented with different types of educational activities. EKRUPAY may, in its sole discretion and at any time, update, change, suspend, make improvements to or discontinue any aspect of the Service, temporarily or permanently.</p>
                    <h4>Registration</h4>
                    <p>
                        In connection with registering for and using the Service, you agree (i) to provide accurate, current and complete information about you and/or your organization as requested by EKRUPAY; (ii) to maintain the confidentiality of your password and other information related to the security of your account; (iii) to maintain and promptly update any registration information you provide to EKRUPAY while registering or during your usage of EKRUPAY's services, to keep such information accurate, current and complete; (iv) to be fully responsible for all use of your account and for any actions that take place through your account; (v) You consent to be contacted by us via phone calls and/or SMS notifications, in case of any order or shipment or delivery related updates; and (vi) You are required to provide your examination registration information for the purpose of our internal estimation of successful candidates. If you do not provide the information, your partial or full access to the website content and/or other EKRUPAY services may be blocked. No refund will be provided in such scenario. We assure you that we will not use this information for any other purpose or share this information with any third party.
                    </p>
                    <p>
                        Your Representations and Warranties You represent and warrant to EKRUPAY that your access and use of the Service will be in accordance with these Terms and Conditions and with all applicable laws, rules and regulations of Constitution of India and any other relevant jurisdiction, including those regarding online conduct or acceptable content, and those regarding the transmission of data or information exported from India and/or the jurisdiction in which you reside. You further represent and warrant that you have created or own any material you submit to EKRUPAY and that you have the right to grant us a license to use that material.
                    </p>
                    <p>
                        Inappropriate Use You will not upload, display or otherwise provide on or through the Service any content that: (i) is libelous, defamatory, abusive, threatening, harassing, hateful, offensive or otherwise violates any law or infringes upon the right of any third party (including copyright, trademark, privacy, publicity or other personal or proprietary rights); or (ii) in EKRUPAY's sole judgment, is objectionable or which restricts or inhibits any other person from using the Service or which may expose EKRUPAY or its users to any harm or liability of any kind.
                    </p>
                    <p>
                        Indemnification of EKRUPAY You agree to defend, indemnify and hold harmless EKRUPAY and its directors, officers, employees, contractors, agents, suppliers, licensors, successors and assigns, from and against any and all losses, claims, causes of action, obligations, liabilities and damages whatsoever, including attorneys' fees, arising out of or relating to your access or use of the Service, any false representation made to us (as part of these Terms and Conditions or otherwise), your breach of any of these Terms and Conditions ,or any claim that any service we provide to you is inaccurate, inappropriate or defective in any way whatsoever.
                    </p>
                    <p>
                        No Representations or Warranties The service, including all images, audio files and other content therein, and any other information, property and rights granted or provided to you by EKRUPAY are provided to you on an "as is" basis. EKRUPAY and its suppliers make no representations or warranties of any kind with respect to the service, either express or implied, and all such representations and warranties, including warranties of merchantability, fitness for a particular purpose or non-infringement, are expressly disclaimed. Without limiting the generality of the foregoing, EKRUPAY does not make any representation or warranty of any kind relating to accuracy, service availability, completeness, informational content, error-free operation, results to be obtained from use, or non-infringement. Access and use of the service may be unavailable during periods of peak demand, system upgrades, malfunctions or scheduled or unscheduled maintenance or for other reasons. Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.
                    </p>
                    <h4>Limitation on types of Damages/Limitation of Liability</h4>
                    <p>
                        In no event will EKRUPAY be liable to you or any third party claiming through you (whether based in contract, tort, strict liability or other theory) for indirect, incidental, special, consequential or exemplary damages arising out of or relating to the access or use of, or the inability to access or use, the service or any portion thereof, including but not limited to the loss of use of the service, inaccurate results, loss of profits, business interruption, or damages stemming from loss or corruption of data or data being rendered inaccurate, the cost of recovering any data, the cost of substitute services or claims by third parties for any damage to computers, software, modems, telephones or other property, even if EKRUPAY has been advised of the possibility of such damages. EKRUPAY's liability to you or any third party claiming through you for any cause whatsoever, and regardless of the form of the action, is limited to the amount paid, if any, by you to EKRUPAY for the service in the 12 months prior to the initial action giving rise to liability. This is an aggregate limit. The existence of more than one claim hereunder will not increase this limit.
                    </p>
                    <h4>Termination</h4>
                    <p>
                        EKRUPAY may terminate your access and use of the Service immediately at any time, for any reason, and at such time you will have no further right to use the Service. You may terminate your EKRUPAY account at any time by following the instructions available through the Service. The provisions of these Terms and Conditions relating to the protection and enforcement of EKRUPAY's proprietary rights, your representations and warranties, disclaimer of representations and warranties, release and indemnities, limitations of liability and types of damages, ownership of data and information, governing law and venue, and miscellaneous provisions shall survive any such termination.
                    </p>
                    <h4>Proprietary Rights in Service Content and Activity Data</h4>
                    <p>All content available through the Service, including designs, text, graphics, images, information, software, audio and other files, and their selection and arrangement (the "Service Content"), are the proprietary property of EKRUPAY or its licensors. No Service Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, other than as expressly permitted in these Terms and Conditions. You may not use any data mining, robots, scraping or similar data gathering or extraction methods to obtain Service Content. As between you and EKRUPAY, all data and information generated from your access and use of the educational activities made available on or through the Service, including translated content generated by you (collectively, the "Activity Data"), shall be exclusively owned by EKRUPAY, and you shall not have any right to use such Activity Data except as expressly authorized by these Terms and Conditions. Activity Data will not include material you submit for translation or any resulting translation. By using the Service, you hereby assign to EKRUPAY any and all rights, title and interest, including any intellectual property rights or proprietary rights, in the Activity Data.</p>
                    <p>
                        All rights of EKRUPAY or its licensors that are not expressly granted in these Terms and Conditions are reserved to EKRUPAY and its licensors.
                    </p>
                    <h4>Trademarks</h4>
                    <p>
                        "EKRUPAY" and all other trademarks, service marks, graphics and logos used in connection with the Service are trademarks or service marks of EKRUPAY or their respective owners. Access and use of the Service does not grant or provide you with the right or license to reproduce or otherwise use the EKRUPAY name or any EKRUPAY or third-party trademarks, service marks, graphics or logos.
                    </p>
                    <h4>Refund Policy</h4>
                    <ul>
                        <li>• The refund is applicable for our products/services only until it's dispatched or the login details for online access to test material etc. are disclosed/dispatched to you.</li>
                        <li>• Please include your order number (sent to you via email after your order) and do tell us why you're requesting a refund. We take customer feedback very seriously and use it to constantly improve our products and quality of service.</li>
                        <li>• All refunds, if eligible, will be processed within 14 (Fourteen) business days of receiving cancellation request and mode of refund would be same through which the original transaction was done.</li>
                    </ul>
                    <h4>Notice for Claims of Copyright Violations and Agent for Notice</h4>
                    <p>
                        If you are a copyright owner and have a good faith belief that any material available through the service infringes upon your copyrights, you may submit a copyright infringement notification to EKRUPAY pursuant to the Digital Millennium Copyright Act by providing us with the following information in writing:
                    </p>
                    <ul>
                        <li>• An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner of the copyright interest;</li>
                        <li>• A description of the copyrighted work that you claim has been infringed;</li>
                        <li>• A description of where the material that you claim is infringing is located on the Service, with enough detail that we may find it on the Service;</li>
                        <li>• Your address, telephone number, and email address;</li>
                        <li>• A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
                        <li>• A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or are authorized to act on the copyright owner's behalf.</li>
                    </ul><br />
                    <p>
                        Please consult your legal counsel for further details or see Section 51 of The Copyright Act, 1957. EKRUPAY's Agent for Notice of claims of copyright infringement can be reached as follows: By email: info@EKRUPAY.com
                    </p>
                    <h4>Governing Law and Arbitration</h4>
                    <p>
                        These Terms and Conditions, its subject matter and EKRUPAY's and your respective rights under these Terms and Conditions shall be governed by the laws of India and you agree that the courts of Mumbai will have exclusive jurisdiction over any dispute (contractual or non-contractual) concerning these terms.
                    </p>
                    <h4>Miscellaneous</h4>
                    <p>
                        These Terms and Conditions constitute the entire agreement between EKRUPAY and you concerning the subject matter hereof. In the event that any of the Terms and Conditions are held by a court or other tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary so that these Terms and Conditions shall otherwise remain in full force and effect. A waiver by EKRUPAY or you of any provision of these Terms and Conditions or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. EKRUPAY may assign its rights or obligations under these Terms and Conditions without condition. These Terms and Conditions will be binding upon and will inure to the benefit of EKRUPAY and you, and EKRUPAY's and your respective successors and permitted assigns.
                    </p>
                </div>
                {/* Footer Section */}
                <footer className="footer1 p-4" style={{ backgroundColor: "#E6DBFF" }}>
                    <div className="container d-flex">
                        <div class="copyright-txt text-center">
                            Copyright © EK RUPAY, All rights Reserved <br />
                            <small style={{ color: "#4620A0" }}><b>Powered By SVS COMMUNICATION</b></small>
                        </div>
                        <div className="ml-auto privacy-policy">
                            <a href="/privacy-policy" className="mr-3">Privacy Policy</a>
                            <a href="/terms-and-conditions" className="terms">Terms and Conditions</a>
                            <p className="text-center">Return to <a href="/" style={{ color: '#4620A0', fontWeight: 'bold' }}>HOME</a></p>
                        </div>
                    </div>
                </footer>
                {/* Footer Section */}
            </div>
        )
    }
}

export default TermsAndConditions