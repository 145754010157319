import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { numberFormat } from '../../../App'
import swal from 'sweetalert';
import apicaller from '../../../util/ApiCaller';

export default function BillingTransactionTable(props) {

    const [transactions, setTransactions] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day'));
    const [endDate, setEndDate] = useState(moment().endOf('day'));


    useEffect(() => {
        fetchTransactions();
    }, [])

    const fetchTransactions = () => {
        let data = {
            query: {},
            pageSize: 5,
            pageNum: 1,
            startDate: startDate,
            endDate: endDate
        }
        apicaller('post', `merchant/${props?.userId}/get-billing-transactions`, data)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.transactions) {
                        setTransactions(res.data.data.transactions)
                    }
                    else {
                        swal('Error', "some internal issue", 'error');
                    }
                }
                else {
                    swal('Error', "some internal issue", 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }


    return (
        <div>
            <div class="table-responsive">
                <table id="example4" class="display min-w850">
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Transaction Id</th>
                            <th>PG Transaction Id</th>
                            <th>Order Id</th>
                            <th>Amount </th>
                            <th>Status</th>
                            <th>Created Date</th>
                            <th>Service Type </th>
                            {/* <th>Payment Method </th> */}
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr></tr>
                        {(transactions && transactions.length > 0) ? transactions.map((item, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.transactionId ? item.transactionId : '-'}</td>
                                <td>{item.razorpayPaymentId ? item.razorpayPaymentId : '-'}</td>
                                <td>{item.razorpayOrderId ? item.razorpayOrderId : '-'}</td>
                                <td>{item.amount ? numberFormat(item.amount) : '-'}</td>
                                {item.isPaid ?
                                    <td><span class="badge light badge-success">Paid</span></td>
                                    : item.isPaymentStepDone ?
                                        <td><span class="badge light badge-danger">Failed</span></td>
                                        :
                                        <td><span class="badge light badge-warning">Unpaid</span></td>
                                }
                                <td>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY hh:mm:ss') : '-'}</td>
                                <td>{item.serviceType == "RP" ? "Rental Payment" : item.serviceType == "IP" ? "institutional Payment" : item.serviceType == "GP" ? "Grocery Payment" : item.serviceType == "PW" ? "Wallet Transfer" : null}</td>
                                {/* <td>{item.paymentMethod ? item.paymentMethod : '-'}</td> */}
                                <td>{item.transactionType}</td>
                            </tr>
                        )) : <tr>No records available</tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
