import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import DataCard from '../../components/merchant/dashboard/DataCard';
import ServiceCard from '../../components/merchant/dashboard/ServiceCard';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import apicaller from '../../util/ApiCaller';
import { getUserData } from '../../modules/reducers';
import { numberFormat } from '../../App';
import swal from 'sweetalert';
import BillingTransactionTable from './components/BillingTransactionTable';
import DMRTransactionsTable from './components/DMRTransactionsTable';

document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
        document.activeElement.blur();
    }
});

class MerchantDashboard extends Component {
    constructor() {
        super();
        this.state = {
            serviceDetails: {},
            selectedService: "",
            selectedServiceShort: "",
            isLoading: false,
            grossAmount: '',
            configurations: {}
        }
    }
    componentDidMount() {
        this.fetchGrossAmount();
        this.fetchConfiguration();
    }

    fetchGrossAmount = () => {
        apicaller('get', `merchant/${this.props.user._id}/get-gross-amount`)
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        pgGrossAmount: res.data.data.pgGrossAmount,
                        dmrGrossAmount: res.data.data.dmrGrossAmount
                    })
                }
            })
    }

    handleOnChangeFields = (Field, value) => {
        let serviceDetails = this.state.serviceDetails;
        serviceDetails[Field] = value
        this.setState({
            serviceDetails,
        })
    }

    verifyServiceDetails = (input) => {
        let output = {};
        output.isValid = true;
        output.message = "";
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!input.receiptName || input.receiptName == "") {
            output.isValid = false;
            output.message = "Please enter name";
            return output;
        }
        if (!input.receiptPhone || input.receiptPhone == "" || !rp.test(input.receiptPhone)) {
            output.isValid = false;
            output.message = "Please enter a valid phone number";
            return output;
        }
        if (!input.receiptEmail || input.receiptEmail == "" || !re.test(input.receiptEmail)) {
            output.isValid = false;
            output.message = "Please enter a valid email";
            return output;
        }
        if (!input.amount || input.amount == "" || input.amount <= 0) {
            output.isValid = false;
            output.message = "Please enter valid amount";
            return output;
        }
        return output;
    }

    handleSelectService = (service_name, short_name) => {
        if (this.props.user.isActive) {
            this.setState({
                selectedService: service_name,
                selectedServiceShort: short_name
            }, () => {
                document.getElementById('model-button').click();
            })
        }
        else {
            swal('Alert', "this service is unavailable", 'error');
        }
    }

    handleOnSubmitPayment = () => {
        this.setState({
            isLoading: true
        })
        let serviceDetails = this.state.serviceDetails;
        let data = {
            "amount": serviceDetails.amount,
            "serviceType": this.state.selectedServiceShort,
            "receiptPhone": serviceDetails.receiptPhone,
            "receiptEmail": serviceDetails.receiptEmail,
            "receiptName": serviceDetails.receiptName,
            // "gateway": serviceDetails.gateway,
        };
        let validation = this.verifyServiceDetails(data);
        if (validation.isValid) {
            apicaller('post', `pgTransactions/merchant/${this.props.user._id}/create_link`, data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.transaction) {
                            this.props.history.push({
                                pathname: `/merchant/billing/trasactions/${res.data.data.transaction._id}`
                            })
                            document.getElementById('model-close-button').click();
                        }
                        else {
                            swal("some internal issue")
                        }
                    }
                    else {
                        swal("some internal issue")
                    }
                    this.setState({
                        isLoading: false
                    })
                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    swal('Error', err, 'error');
                })
        }
        else {
            this.setState({
                isLoading: false
            })
            swal(validation.message, "", "warning");
        }
    }

    handleSelectComingSoon = () => {
        document.getElementById('comingSoonModal-button').click();
    }

    stopMarquee = () => {
        document.getElementById('marquee').stop();
    }

    startMarquee = () => {
        document.getElementById('marquee').start();
    }

    fetchConfiguration = () => {
        apicaller('get', 'admin/config/get')
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        configurations: res?.data?.data
                    })
                }
            })
            .catch(err => {
                if (err) {
                    console.log(err);
                }
            })
    }

    render() {
        return (
            <div>
                <title>Merchant Dashboard</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Dashboard"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            {this.state?.configurations?.scroll_text ?
                                <marquee
                                    bgcolor='#ded3f8'
                                    direction="left"
                                    id="marquee"
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onMouseOver={() => {
                                        this.stopMarquee();
                                    }}
                                    onMouseOut={() => {
                                        this.startMarquee();
                                    }}
                                >
                                    <p style={{
                                        margin: 0,
                                        color: '#DC3444',
                                        fontWeight: 'bold'
                                    }}>
                                        *{this.state?.configurations?.scroll_text}
                                    </p>
                                </marquee>
                                :
                                null
                            }
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <p style={{
                                    color: 'blue',
                                    fontSize: '18px',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    navigator.clipboard.writeText(`${window.location.hostname}/masterLink?id=${this.props.user._id}&name=${this.props?.user?.organization_name?.replace(/ /g, '_')}`)
                                    swal('Copied', "Master link copied to clipboard.", 'success');
                                }}>
                                    <i class="fa fa-copy"></i> Copy Master Link
                                </p>
                            </div>
                            <div class="row">
                                <DataCard
                                    cardColor="primary"
                                    icon="zmdi-balance-wallet"
                                    iconColor="white"
                                    iconSize="zmdi-hc-3x"
                                    cardText="Wallet Balance"
                                    cardValue={numberFormat(this.props.user.wallet_balance)}
                                />
                                {/* <DataCard
                                    cardColor="success"
                                    icon="zmdi-balance-wallet"
                                    iconColor="white"
                                    iconSize="zmdi-hc-3x"
                                    cardText="PG Wallet Balance"
                                    cardValue={numberFormat(this.props.user.pg_balance)}
                                /> */}
                                {this.state.pgGrossAmount ?
                                    <DataCard
                                        cardColor="warning"
                                        icon="zmdi-balance-wallet"
                                        iconColor="white"
                                        iconSize="zmdi-hc-3x"
                                        cardText="PG Gross Amount"
                                        cardValue={numberFormat(this.state.pgGrossAmount)}
                                    />
                                    : null}
                                {this.state.dmrGrossAmount ?
                                    <DataCard
                                        cardColor="info"
                                        icon="zmdi-balance-wallet"
                                        iconColor="white"
                                        iconSize="zmdi-hc-3x"
                                        cardText="DMR Gross Amount"
                                        cardValue={numberFormat(this.state.dmrGrossAmount)}
                                    />
                                    : null}
                            </div>
                            <h1 className="mt-4 mb-4">Today's Latest transactions</h1>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className="card-body">
                                            <h5>Billing Transactions</h5>
                                            <BillingTransactionTable
                                                userId={this?.props?.user?._id}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className="card-body">
                                            <h5>Dmr Transactions</h5>
                                            <DMRTransactionsTable
                                                userId={this?.props?.user?._id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-4 mb-4">Billing Services</h1>
                            <div class="row">
                                {this.props.user.isRentalServiceAllowed ?
                                    <ServiceCard
                                        handleSelectService={this.handleSelectService}
                                        serviceName="Rental Bill Payment"
                                        serviceShortName="RP"
                                        iconImage={(<img alt="image" width={60} src="/images/building.svg" />)}
                                        cardText="Rental bill"
                                    />
                                    : null}
                                {this.props.user.isGroceryServiceAllowed ?
                                    <ServiceCard
                                        handleSelectService={this.handleSelectService}
                                        serviceName="Grocery Bill Payment"
                                        serviceShortName="GP"
                                        iconImage={(<img alt="image" width={60} src="/images/grocery.svg" />)}
                                        cardText="Grocery Bill"
                                    />
                                    : null}
                                {this.props.user.isInstitutionalServiceAllowed ?
                                    <ServiceCard
                                        handleSelectService={this.handleSelectService}
                                        serviceName="Institutional Fee Payment"
                                        serviceShortName="IP"
                                        iconImage={(<img alt="image" width={60} src="/images/books.svg" />)}
                                        cardText="Institutional fee"
                                    />
                                    : null}
                                <ServiceCard
                                    handleSelectService={() => { this.props.user.isActive ? this.props.history.push('/dmr/transfer') : swal("Alert", "this service is unavailable", "error") }}
                                    serviceName="DMR"
                                    serviceShortName="DMR"
                                    iconImage={(<img alt="image" width={60} src="/images/money-transfer.svg" />)}
                                    cardText="DMR"
                                />
                            </div>
                            <h1 className="mt-4 mb-4">Utility Services</h1>
                            <div className="row">
                                <ServiceCard
                                    handleSelectService={this.handleSelectComingSoon}
                                    serviceName="Electricity Bill Payment"
                                    serviceShortName="EP"
                                    iconImage={(<img alt="image" width={60} src="/images/plug.svg" />)}
                                    cardText="Electricity Bill Payment"
                                />
                                <ServiceCard
                                    handleSelectService={this.handleSelectComingSoon}
                                    serviceName="Water Bill Payment"
                                    serviceShortName="WP"
                                    iconImage={(<img alt="image" width={80} src="/images/water_bill.svg" />)}
                                    cardText="Water Bill Payment"
                                />
                                <ServiceCard
                                    handleSelectService={this.handleSelectComingSoon}
                                    serviceName="Mobile Recharge"
                                    serviceShortName="MR"
                                    iconImage={(<img alt="image" width={80} src="/images/smartphone.svg" />)}
                                    cardText="Mobile Recharge"
                                />
                                <ServiceCard
                                    handleSelectService={this.handleSelectComingSoon}
                                    serviceName="DTH Recharge"
                                    serviceShortName="DR"
                                    iconImage={(<img alt="image" width={80} src="/images/satellite-tv.svg" />)}
                                    cardText="DTH Recharge"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" className="d-none" id="model-button" data-toggle="modal" data-target="#exampleModalCenter"></button>
                <button type="button" className="d-none" id="comingSoonModal-button" data-toggle="modal" data-target="#comingSoonModal"></button>


                <div className="modal fade" id="exampleModalCenter">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form onSubmit={(e) => { e.preventDefault(); this.handleOnSubmitPayment() }}>
                                    <h4>{this.state.selectedService}</h4>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Name</strong></label>
                                                <input type="text" name="receiptName" className="form-control" onChange={(e) => this.handleOnChangeFields("receiptName", e.target.value)} placeholder="Enter Name" required />
                                                <label className="text-label"><strong>Email</strong></label>
                                                <input type="email" name="receiptEmail" className="form-control" onChange={(e) => this.handleOnChangeFields("receiptEmail", e.target.value)} placeholder="Enter Email" required />
                                                <label className="text-label"><strong>Phone Number</strong></label>
                                                <input type="text" name="receiptPhone" className="form-control" onChange={(e) => this.handleOnChangeFields("receiptPhone", e.target.value)} placeholder="Enter Phone Number" required />
                                                <label className="text-label"><strong>Amount to be collected</strong></label>
                                                <input type="number" name="amount" min="0" className="form-control" onChange={(e) => this.handleOnChangeFields("amount", e.target.value)} placeholder="Enter Amount to be collected" required />
                                                {/* <label className="text-label"><strong>Select Payment Gateway</strong></label><br />
                                                <input type="radio" name="selectedGateway" id="pg1" required onChange={(e) => this.handleOnChangeFields("gateway", "razorpay")} />&nbsp;&nbsp;
                                                <label className="text-label" for="pg1">Payment Gateway 1</label>
                                                <br />
                                                <input type="radio" name="selectedGateway" id="pg2" required onChange={(e) => this.handleOnChangeFields("gateway", "easebuzz")} />&nbsp;&nbsp;
                                                <label className="text-label" for="pg2">Payment Gateway 2</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-block" disabled={this.state.isLoading}>{this.state.isLoading ? "Submitting" : "Submit"}</button>
                                        <button type="button" className="d-none" id="model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="comingSoonModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <h1>Coming Soon</h1>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        user: getUserData(state),
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MerchantDashboard);
