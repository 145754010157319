import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import DataCard from '../../components/customer/dashboard/DataCard';
import ServiceCard from '../../components/customer/dashboard/ServiceCard';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";

class CustomerDashboard extends Component {

    constructor() {
        super();
        this.state = {
            serviceDetails: {},
            isLoading: false,
        }
    }


    handleSelectService = (service_name, short_name) => {
        this.props.history.push({
            pathname: '/accounts/list',
            state: {
                selectedService: service_name,
                selectedServiceShort: short_name
            }
        })
    }


    render() {
        return (
            <div>
                <title>Customer Dashboard</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Dashboard"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            {/* <div class="row">
                                <DataCard
                                    cardColor="primary"
                                    icon="zmdi-balance-wallet"
                                    iconColor="white"
                                    iconSize="zmdi-hc-3x"
                                    cardText="Wallet Balance"
                                    cardValue="5000"
                                />
                            </div> */}
                            <h1 className="mt-4 mb-4">Services</h1>
                            <div class="row">
                                <ServiceCard
                                    handleSelectService={this.handleSelectService}
                                    serviceName="Rental Bill Payment"
                                    serviceShortName="RP"
                                    iconImage={(<img alt="image" width={60} src="/images/building.svg" />)}
                                    cardText="Rental bill"
                                />
                                <ServiceCard
                                    handleSelectService={this.handleSelectService}
                                    serviceName="Grocery Bill Payment"
                                    serviceShortName="GP"
                                    iconImage={(<img alt="image" width={60} src="/images/grocery.svg" />)}
                                    cardText="Grocery Bill"
                                />
                                <ServiceCard
                                    handleSelectService={this.handleSelectService}
                                    serviceName="Institutional Fee Payment"
                                    serviceShortName="IP"
                                    iconImage={(<img alt="image" width={60} src="/images/books.svg" />)}
                                    cardText="Institutional fee"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CustomerDashboard);
