import React, { Component } from 'react';
import { connect } from "react-redux";
import { loginUser, logoutUser } from "../../modules/actions";
import { getErrorMessage, getErrorStatus, getisAuthenticated, getLoggingStatus, getUserData } from '../../modules/reducers';
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import apicaller from '../../util/ApiCaller';
import Loader from '../../components/global/Loader';
import swal from 'sweetalert';

class MerchantLogin extends Component {
    constructor() {
        super();
        this.state = {
            user: {}
        }
    }

    componentDidMount() {
        this.props.logoutUser()
    }

    componentDidUpdate() {
        if (this.props.isAuthenticated) {
            if (this.props.user.role == 2) {
                this.props.history.push('/customer/dashboard');
            }
            else if (this.props.user.role == 1) {
                this.props.history.push('/merchant/dashboard');
            }
        }
        else if (this.props.errorStatus) {
            // alert(this.props.errorMessage);
            swal("Alert", this.props.errorMessage, "error")
        }
    }

    handleOnChangeFields = (userField, value) => {
        let user = this.state.user;
        user[userField] = value
        this.setState({
            user
        })
    }

    validateUserFields(input) {
        let output = {};
        output.isvalid = true;
        output.message = "";
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const rp = /[0-9]{10}/;
        if (!input.userName || input.userName == "") {
            output.isvalid = false;
            output.message = "Please enter Username";
        }
        else if (!re.test(String(input.userName).toLowerCase()) && !rp.test(input.userName)) {
            output.isvalid = false;
            output.message = "Please enter a valid phone or email"
        }
        else if (!input.password || input.password == "") {
            output.isvalid = false;
            output.message = "Please enter password";
        }
        return output;
    }

    handleOnLogin = () => {
        let user = this.state.user;
        let validation = this.validateUserFields(user);
        if (validation.isvalid) {
            let data = new FormData();
            data.append("name", user.userName);
            data.append("password", user.password);
            let role = 'merchant';
            this.props.loginUser(data, role);
        }
        else {
            swal(validation.message);
        }
    }

    handleOnForgotPassword = () => {
        let user = this.state.user;
        let data = {
            userName: user.userName
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const rp = /[0-9]{,10}/;
        if (user.userName && user.userName != "" && re.test(String(user.userName).toLowerCase()) || rp.test(user.userName)) {
            apicaller('post', 'user/forgotPassword', data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.sentMailStatus) {
                            swal('mail sent, please check your mail');
                            document.getElementById('model-close-button').click();
                        }
                        else {
                            swal('some internal issue please try again');
                            document.getElementById('model-close-button').click();
                        }
                    }
                })
        }
        else {
            swal('please enter email / phone number');
        }
    }

    render() {
        return (
            <div>
                {this.props.loggingStatus || this.props.isAuthenticated ?
                    <Loader /> :
                    <div>
                        <meta charSet="utf-8" />
                        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        {/* Theme tittle */}
                        <title>Ek Rupay</title>
                        {/* Theme style CSS */}
                        <link href="css/style.css" rel="stylesheet" />
                        <link href="./vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
                        <link rel="stylesheet" href="./vendor/chartist/css/chartist.min.css" />
                        {/* Vectormap */}
                        <link href="./vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
                        <link href="./vendor/bootstrap-select/dist/css/bootstrap-select.min.css" rel="stylesheet" />
                        <link href="./css/style.css" rel="stylesheet" />
                        <link href="https://cdn.lineicons.com/2.0/LineIcons.css" rel="stylesheet" />
                        <link href="./vendor/owl-carousel/owl.carousel.css" rel="stylesheet" />
                        <div className="authincation mt-6 h-100">
                            <div className="container h-100">
                                <div className="row justify-content-center h-100 align-items-center">
                                    <div className="col-md-6">
                                        <h1 className="text-center">EKRUPAY</h1>
                                        <h3 className="text-center mb-4" style={{ color: 'black' }}>Merchant Sign in</h3>
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <form onSubmit={(e) => { e.preventDefault(); this.handleOnLogin() }}>
                                                        <div className="auth-form">
                                                            {/* <h4 className="text-center mb-4 text-white">Merchant Sign in</h4> */}
                                                            <div className="form-group">
                                                                <label className="mb-1 text-white"><strong>Email / Phone Number</strong></label>
                                                                <input type="text" className="form-control" placeholder="Enter Email or Phone Number" name="userName" onChange={(e) => this.handleOnChangeFields("userName", e.target.value)} required />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 text-white"><strong>Password</strong></label>
                                                                <input type="password" className="form-control" placeholder="Enter Password" name="password" onChange={(e) => this.handleOnChangeFields("password", e.target.value)} required />
                                                            </div>
                                                            <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                                {/* <div className="form-group">
                                                                    <div className="custom-control custom-checkbox ml-1 text-white">
                                                                        <input type="checkbox" className="custom-control-input" id="basic_checkbox_1" />
                                                                        <label className="custom-control-label" htmlFor="basic_checkbox_1">Remember me</label>
                                                                    </div>
                                                                </div> */}
                                                                <div className="ml-auto form-group">
                                                                    <a className="text-white" data-toggle="modal" data-target="#exampleModalCenter">Forgot Password?</a>
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="btn bg-white text-primary btn-block">Login</button>
                                                            </div>
                                                            <div className="new-account mt-3">
                                                                <p className="text-white">Don't have an account? <a className="text-white" href="/merchant/signup">Sign up</a></p>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                {/* Modal */}
                <div className="modal fade" id="exampleModalCenter">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Email / Phone</strong></label>
                                            <input type="text" name="phone" className="form-control" onChange={(e) => this.handleOnChangeFields("userName", e.target.value)} placeholder="Enter Registered Phone Number or Email" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" onClick={this.handleOnForgotPassword} className="btn btn-primary btn-block">Submit</button>
                                    <button type="button" className="d-none" id="model-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: getisAuthenticated(state),
        user: getUserData(state),
        errorStatus: getErrorStatus(state),
        errorMessage: getErrorMessage(state),
        loggingStatus: getLoggingStatus(state),
    }
}

const mapDispatchToProps = dispatch => ({
    loginUser: (email, password) => dispatch(loginUser(email, password)),
    logoutUser: () => dispatch(logoutUser()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MerchantLogin);



