import React, { Component } from 'react';

class ServiceCard extends Component {
    render() {
        let icon = `zmdi ${this.props.icon} ${this.props.iconSize}`;
        let iconColor = this.props.iconColor;
        return (
            <div className="col-xl-3 col-xxl-3 col-md-3 col-sm-4 dashboard-card-cols" style={{ cursor: 'pointer' }}>
                <a onClick={() => this.props.handleSelectService(this.props.serviceName, this.props.serviceShortName)} className="dashborad-cards">
                    <div className="card text-center">
                        <div className="card-body">
                            {this.props.icon ?
                                <i className={icon} style={{ color: iconColor }} />
                                : this.props.iconImage}
                            <h6 className="font-w600 text-black fs-16 mb-1 mt-3">{this.props.cardText}</h6>
                            <span className="fs-14">{this.props.cardSubText}</span>
                        </div>
                    </div>
                </a>
            </div>
        )
    }
}

export default ServiceCard;