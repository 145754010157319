import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import apicaller from '../../util/ApiCaller';
import { getUserData } from '../../modules/reducers';
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import { connect } from "react-redux";

class UserSupport extends Component {
    constructor() {
        super();
        this.state = {
            issue: "",
            loading: false
        }
    }

    handleOnChangeFields = (userField, value) => {
        this.setState({
            [userField]: value
        })
    }

    handleOnSendIssue = () => {
        let data = {};
        data.issue = this.state.issue;
        this.setState({
            loading: true
        })
        apicaller('post', `user/${this.props.user._id}/raise/ticket`, data)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.raised_ticket) {
                        alert('Ticket raised successfully, we will contact you soon');
                        document.getElementById('issue').value = "";
                    }
                    else {
                        alert('some internal issue');
                    }
                }
            })
            .catch(err => {
                alert(err);
            })
        this.setState({
            loading: false
        })
    }
    render() {
        return (
            <div>
                <title>Support</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Support"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div className="row">
                                {/* Column starts */}
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-header d-block">
                                            <h4 className="card-title">FAQ's</h4>
                                        </div>
                                        <div className="card-body">
                                            {/* Default accordion */}
                                            <div id="accordion-one" className="accordion accordion-primary">
                                                <div className="accordion__item">
                                                    <div className="accordion__header rounded-lg" data-toggle="collapse" data-target="#default_collapseOne">
                                                        <span className="accordion__header--text">What is EKRUPAY?</span>
                                                        <span className="accordion__header--indicator" />
                                                    </div>
                                                    <div id="default_collapseOne" className="collapse accordion__body show" data-parent="#accordion-one">
                                                        <div className="accordion__body--text">
                                                            EKRUPAY is Online portal for delivery of services like Recharges, Bill payments, Rental Payments, Grocery Payments, Institutional Fee Payments.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion__item">
                                                    <div className="accordion__header collapsed rounded-lg" data-toggle="collapse" data-target="#default_collapseTwo">
                                                        <span className="accordion__header--text">Having an issue with transaction?</span>
                                                        <span className="accordion__header--indicator" />
                                                    </div>
                                                    <div id="default_collapseTwo" className="collapse accordion__body" data-parent="#accordion-one">
                                                        <div className="accordion__body--text">
                                                            Navigate to transactions page and click on more option of a transaction where you can find a option to raise an issue.

                                                            We will get back to you soon.
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="accordion__item">
                                                    <div className="accordion__header collapsed rounded-lg" data-toggle="collapse" data-target="#default_collapseThree">
                                                        <span className="accordion__header--text">Accordion Header Three</span>
                                                        <span className="accordion__header--indicator" />
                                                    </div>
                                                    <div id="default_collapseThree" className="collapse accordion__body" data-parent="#accordion-one">
                                                        <div className="accordion__body--text">
                                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Column ends */}
                            </div>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-header d-block">
                                            <h4 className="card-title">Raise an Issue</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Issue</strong></label>
                                                <textarea class="form-control" id="issue" placeholder="Enter your issue" onChange={(e) => this.handleOnChangeFields("issue", e.target.value)} rows="4"></textarea>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" onClick={this.handleOnSendIssue} className="btn btn-primary btn-block">{this.state.loading ? "Sending" : "Send"}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="card text-white bg-success">
                                                <div className="card-header">
                                                    <h5 className="card-title text-white">Contact Info</h5>
                                                </div>
                                                <div className="card-body mb-0">
                                                    <div className="row">
                                                        <div className="col-xl-12 col-sm-12 mb-2">
                                                            <h5 className="f-w-500 info-heading">Contact Number</h5>
                                                            <span>9390005464</span>
                                                        </div>
                                                        <div className="col-xl-12 col-sm-12 mb-2">
                                                            <h5 className="f-w-500 info-heading">Email Address</h5>
                                                            <span>ekrupay1@gmail.com</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card text-white bg-info">
                                                <div className="card-header">
                                                    <h5 className="card-title text-white">Account Info</h5>
                                                </div>
                                                <div className="card-body mb-0">
                                                    <div className="row">
                                                        <div className="col-xl-12 col-sm-12 mb-2">
                                                            <h5 className="f-w-500 info-heading">Account Number</h5>
                                                            <span>919020031175527</span>
                                                        </div>
                                                        <div className="col-xl-12 col-sm-12 mb-2">
                                                            <h5 className="f-w-500 info-heading">IFSC Code</h5>
                                                            <span>UTIB0001455</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: getUserData(state),
    }
}

const mapDispatchToProps = dispatch => ({

});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UserSupport);