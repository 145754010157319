import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import WalletCard from '../../components/merchant/wallets/WalletCard';
import apicaller from '../../util/ApiCaller';

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import { getUserData } from '../../modules/reducers';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { numberFormat } from '../../App';
import swal from 'sweetalert';

class MerchantProfile extends Component {

    constructor() {
        super();
        this.state = {
            user: {},
            updateUser: {},
            isLightBoxOpen: false,
            selectedImage: '',
            isUpdatingProfile: false,
            isUpdatingOrganizationLogo: false,
            isUpdatingPassword: false,
        }
    }


    componentDidMount() {
        let userId = this.props.match.params.id;
        this.fetchUserProfileDetails(userId);
    }

    getImage = async (fieldName, fileKey) => {
        await apicaller('get', `get/file?fileKey=${fileKey}`)
            .then(res => {
                if (res.status == 200) {
                    let user = this.state.user;
                    let buf = Buffer.from(res.data);
                    let base64 = buf.toString('base64');
                    let final_image = `data:image/jpeg;base64,${base64}`
                    user[fieldName] = final_image
                    this.setState({ user, updateUser: user })
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
    }

    downloadUserImages = async () => {
        if (this.state.user.organization_logo) {
            await this.getImage("organization_logo", this.state.user.organization_logo)
        }
        if (this.state.user.organization_inside_image) {
            await this.getImage("organization_inside_image", this.state.user.organization_inside_image)
        }
        if (this.state.user.organization_outside_image) {
            await this.getImage("organization_outside_image", this.state.user.organization_outside_image)
        }
        if (this.state.user.canceled_check) {
            await this.getImage("canceled_check", this.state.user.canceled_check)
        }

        if (this.state.user.pan_image) {
            await this.getImage("pan_image", this.state.user.pan_image)
        }
        if (this.state.user.aadhar_front_image) {
            await this.getImage("aadhar_front_image", this.state.user.aadhar_front_image)
        }
        if (this.state.user.aadhar_back_image) {
            await this.getImage("aadhar_back_image", this.state.user.aadhar_back_image)
        }
        if (this.state.user.certificate_image) {
            await this.getImage("certificate_image", this.state.user.certificate_image)
        }
    }

    fetchUserProfileDetails = (userId) => {
        apicaller('get', `user/${userId}/profile`)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.user) {
                        this.setState({
                            user: res.data.data.user
                        }, () => {
                            this.downloadUserImages();
                        })
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }

    handleOnChangeFields = (userField, value) => {
        let updateUser = this.state.updateUser;
        updateUser[userField] = value
        this.setState({
            updateUser,
        })
    }

    validateChangePassword(input) {
        let output = {};
        output.isvalid = true;
        output.message = "";
        const rpass = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,16}$/
        if (!input.current_password || input.current_password == "") {
            output.isvalid = false;
            output.message = "Please enter the current password.";
            return output;
        }
        if (!input.new_password || input.new_password == "") {
            output.isvalid = false;
            output.message = "Please enter the new password.";
            return output;
        }
        if (!input.confirm_new_password || input.confirm_new_password == "") {
            output.isvalid = false;
            output.message = "Please enter the new password.";
            return output;
        }
        if (!rpass.test(input.new_password)) {
            output.isvalid = false;
            output.message = "Please check for minimum conditions and enter a new password.";
            return output;
        }

        if (input.new_password != input.confirm_new_password) {
            output.isvalid = false;
            output.message = "Confirm password is incorrect.";
            return output;
        }
        return output;
    }

    handleOnUpdateProfile = () => {
        let data = {};
        data.first_name = this.state.updateUser.first_name;
        data.last_name = this.state.updateUser.last_name;
        data.alternative_phone = this.state.updateUser.alternative_phone;
        data.shop_address = this.state.updateUser.shop_address;
        data.location = this.state.updateUser.location;
        data.account_number = this.state.updateUser.account_number;
        data.ifsc_code = this.state.updateUser.ifsc_code;
        data.account_holder_name = this.state.updateUser.account_holder_name;
        this.setState({
            isUpdatingProfile: true
        })
        apicaller('post', `user/${this.state.user._id}/profile/update`, data)
            .then(res => {
                if (res.status == 200) {
                    swal('Success', "user updated successfully", 'success');
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
                this.setState({
                    isUpdatingProfile: false
                })
            })
            .catch(err => {
                swal('Error', err.message, 'error');
                this.setState({
                    isUpdatingProfile: false
                })
            })
    }

    handleOnUploadImage = (userField, files) => {
        let value;
        for (var i in files) {
            if (i != "length" && i != "item") {
                document.querySelector(`#${userField}`).textContent = files[i].name;
                var FileSize = files[i].size / 1024 / 1024; // in MB
                if (FileSize > 3) {
                    swal('Alert', 'File size exceeds 3 MB', 'warning');
                }
                value = files[i];
            }
        }
        this.setState({
            [userField]: value,
        })
    }

    handleUpdateOrganizationLogo = () => {
        if (this.state.organization_logo) {
            if ((this.state.organization_logo.size / 1024 / 1024) < 3) {
                this.setState({
                    isUpdatingOrganizationLogo: true
                })
                let data = new FormData();
                data.append("organization_logo", this.state.organization_logo);
                apicaller('post', `user/${this.props.user._id}/profile/files/update`, data)
                    .then(res => {
                        console.log(res.data);
                        if (res.status == 200) {
                            swal('Success', 'Logo updated sucessfully.', 'success')
                            document.getElementById('update-logo-model-close-button').click();
                            this.fetchUserProfileDetails(this.props.user._id);
                        }
                        else {
                            swal('Error', 'some internal issue', 'error');
                        }
                        this.setState({
                            isUpdatingOrganizationLogo: false
                        })
                    })
                    .catch(err => {
                        swal('Error', err.message, 'error');
                        this.setState({
                            isUpdatingOrganizationLogo: false
                        })
                    })
            }
            else {
                swal('Warning', 'Merchant logo should be less than 3MB', 'warning')
            }
        }
        else {
            swal('Warning', 'Merchant logo is required', 'warning')
        }
    }

    handleOnUpdatePassword = () => {
        let data = {};
        data.current_password = this.state.updateUser.current_password;
        data.new_password = this.state.updateUser.new_password;
        this.setState({
            isUpdatingPassword: true
        })
        apicaller('post', `user/${this.state.user._id}/password/update`, data)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.user) {
                        swal('Success', 'password updated successfully', 'success');
                    }
                    else {
                        swal('Warning', res.data.data.message, 'warning');
                    }
                    document.getElementById('update-password-model-close-button').click();
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
                this.setState({
                    isUpdatingPassword: false
                })
            })
            .catch(err => {
                swal('Error', err.message, 'error');
                this.setState({
                    isUpdatingPassword: false
                })
            })
    }

    handleShowPasswordConditions = () => {
        let ele = document.getElementById('password');
        ele.style.display = 'block'
    }
    handleHidePasswordConditions = () => {
        let ele = document.getElementById('password');
        ele.style.display = 'none'
    }

    render() {
        return (
            <div>
                <title>Profile</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Profile"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="profile-tab">
                                                <div className="custom-tab-1">
                                                    <ul className="nav nav-tabs">
                                                        <li className="nav-item"><a href="#view-profile" data-toggle="tab" className="nav-link active show">View Profile</a>
                                                        </li>
                                                        <li className="nav-item"><a href="#edit-profile" data-toggle="tab" className="nav-link">Edit Profile</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="view-profile" className="tab-pane fade active show">
                                                            <div className="mt-4">
                                                                <h4 className="mb-3">Basic Details</h4>
                                                                <section className="ml-3">
                                                                    <div className="row">
                                                                        <div className="col-xl-12 col-sm-12 mb-2">
                                                                            <h5 className="f-w-500">Organization Name</h5>
                                                                            <span>{this.state.user.organization_name}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Proprietor First Name</h5>
                                                                            <span>{this.state.user.first_name}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Proprietor Last Name</h5>
                                                                            <span>{this.state.user.last_name}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Phone Number</h5>
                                                                            <span>{this.state.user.phone}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Alternate Phone Number</h5>
                                                                            <span>{this.state.user.alternative_phone}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Email</h5>
                                                                            <span>{this.state.user.email}</span>
                                                                        </div>
                                                                        <div className="col-xl-4 col-sm-4 mb-2">
                                                                            <h5 className="f-w-500">Date of Birth</h5>
                                                                            <span>{this.state.user.dob}</span>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                                <h4 className="mt-4 mb-3">Shop Details</h4>
                                                                <section className="ml-3">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Shop Address</h5>
                                                                            <span>{this.state.user.shop_address}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Location</h5>
                                                                            <span>{this.state.user.location}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Logo of Merchant<i class="zmdi zmdi-edit" data-toggle="modal" data-target="#updateLogoModal" style={{ color: "#40189D", fontSize: 20, paddingLeft: 15, marginTop: 10 }}></i></h5>
                                                                            <img src={this.state.user.organization_logo} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.organization_logo })} alt="" className="mt-2 mb-2 w-25" />
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Shop Photos</h5>
                                                                            <img src={this.state.user.organization_outside_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.organization_outside_image })} alt="" height="80%" width="40%" className="mt-2 mr-2 mb-2" />
                                                                            <img src={this.state.user.organization_inside_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.organization_inside_image })} alt="" height="80%" width="40%" className="mt-2 mb-2" />
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                                <h4 className="mt-4 mb-3">Bank Details</h4>
                                                                <section className="ml-3">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Account Number</h5>
                                                                            <span>{this.state.user.account_number}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">IFSC Code</h5>
                                                                            <span>{this.state.user.ifsc_code}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Account Holder Name</h5>
                                                                            <span>{this.state.user.account_holder_name}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Canceled cheque</h5>
                                                                            <img src={this.state.user.canceled_check} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.canceled_check })} height="80%" width="40%" alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                                <h4 className="mt-4 mb-3">Documents uploaded</h4>
                                                                <section className="ml-3">
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">PAN Card Number</h5>
                                                                            <span>{this.state.user.pan_number}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">PAN Card</h5>
                                                                            <img src={this.state.user.pan_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.pan_image })} height="80%" width="40%" v alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Aadhaar Card Number</h5>
                                                                            <span>{this.state.user.aadhar_number}</span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Aadhaar Card Front Image</h5>
                                                                            <img src={this.state.user.aadhar_front_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.aadhar_front_image })} height="80%" width="40%" alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Aadhaar Card Back Image</h5>
                                                                            <img src={this.state.user.aadhar_back_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.aadhar_back_image })} height="80%" width="40%" alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                        <div className="col-xl-6 col-sm-6 mb-2">
                                                                            <h5 className="f-w-500">Shop Establishment Certificate</h5>
                                                                            <img src={this.state.user.certificate_image} onClick={() => this.setState({ isLightBoxOpen: true, selectedImage: this.state.user.certificate_image })} height="80%" width="40%" alt="" className="mt-2 mb-2 w-50" />
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                        <div id="edit-profile" className="tab-pane fade">
                                                            <form action="#" id="" className="step-form-horizontal mt-4">
                                                                <div>
                                                                    <h4 className="form_title">Basic Details</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Proprietor First Name*</label>
                                                                                    <input type="text" name="firstName" className="form-control" onChange={(e) => this.handleOnChangeFields("first_name", e.target.value)} value={this.state.updateUser.first_name} placeholder="Proprietor First Name" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Proprietor Last Name*</label>
                                                                                    <input type="text" name="lastName" className="form-control" onChange={(e) => this.handleOnChangeFields("last_name", e.target.value)} value={this.state.updateUser.last_name} placeholder="Proprietor Last Name" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Alternate Phone Number*</label>
                                                                                    <input type="text" name="alternatePhoneNumber" className="form-control" onChange={(e) => this.handleOnChangeFields("alternative_phone", e.target.value)} value={this.state.updateUser.alternative_phone} placeholder="Enter Alternate Phone Number" required />
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-lg-6 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Date of Birth*</label>
                                                                                    <input type="date" name="dob" className="form-control" placeholder="Enter Date of Birth" required />
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                    </section>
                                                                    <h4>Shop Details</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Shop Address*</label>
                                                                                    <input type="text" name="shopAddress" className="form-control" onChange={(e) => this.handleOnChangeFields("shop_address", e.target.value)} value={this.state.updateUser.shop_address} placeholder="Enter Shop Address" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Location*</label>
                                                                                    <input type="text" className="form-control" onChange={(e) => this.handleOnChangeFields("location", e.target.value)} value={this.state.updateUser.location} placeholder="Enter Location" required />
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-lg-12 mb-3">
                                                                                <label className="text-label">Logo of Merchant*</label>
                                                                                <div className="input-group">
                                                                                    <div className="custom-file">
                                                                                        <input type="file" className="custom-file-input" required />
                                                                                        <label className="custom-file-label">Choose file</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                    </section>
                                                                    <h4>Bank Details</h4>
                                                                    <section>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Account Number*</label>
                                                                                    <input type="text" name="accountNumber" className="form-control" onChange={(e) => this.handleOnChangeFields("account_number", e.target.value)} value={this.state.updateUser.account_number} placeholder="Enter Account Number" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">IFSC Code*</label>
                                                                                    <input type="text" name="ifscCode" className="form-control" onChange={(e) => this.handleOnChangeFields("ifsc_code", e.target.value)} value={this.state.updateUser.ifsc_code} placeholder="Enter IFSC Code" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-label">Account Holder Name*</label>
                                                                                    <input type="text" className="form-control" onChange={(e) => this.handleOnChangeFields("account_holder_name", e.target.value)} value={this.state.updateUser.account_holder_name} placeholder="Enter Account Holder Name" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 mt-2 mb-2 text-center">
                                                                                <button type="button" onClick={this.handleOnUpdateProfile} className="btn btn-primary btn-block" disabled={this.state.isUpdatingProfile}>{this.state.isUpdatingProfile ? "Updating..." : "Update"}</button>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <button className="btn btn-primary btn-rounded mb-2" data-toggle="modal" data-target="#changePasswordModal">Change Password</button>
                                            <div className="row">
                                                {/* <WalletCard
                                                    heading="Payment Gateway Wallet"
                                                    amount={numberFormat(this.state.user.pg_balance)}
                                                    cardColor="primary"
                                                    cardCol="col-xl-12"
                                                /> */}
                                                <WalletCard
                                                    heading="Wallet"
                                                    amount={numberFormat(this.state.user.wallet_balance)}
                                                    cardColor="success"
                                                    cardCol="col-xl-12"
                                                />
                                            </div>
                                            <p style={{
                                                color: 'blue',
                                                fontSize: '18px',
                                                cursor: 'pointer'
                                            }} onClick={() => {
                                                navigator.clipboard.writeText(`${window.location.hostname}/masterLink?id=${this.props.user._id}&name=${this.props?.user?.organization_name?.replace(/ /g, '_')}`)
                                                swal('Copied', "Master link copied to clipboard.", 'success');
                                            }}>
                                                <i class="fa fa-copy"></i> Copy Master Link
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isLightBoxOpen && (
                    <Lightbox
                        mainSrc={this.state.selectedImage}
                        onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
                    />
                )}
                {/* Modal */}
                <div className="modal fade" id="changePasswordModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Change Password</div>
                            <div className="modal-body">
                                <form onSubmit={(e) => { e.preventDefault(); this.handleOnUpdatePassword() }}>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Current Password</strong></label>
                                                <input type="password" name="current_password" value={this.state.updateUser.current_password} onChange={(e) => this.handleOnChangeFields("current_password", e.target.value)} className="form-control" placeholder="Enter Current Password" required />
                                            </div>
                                            <div className="form-group">
                                                <label className="text-label"><strong>New Password</strong></label>
                                                <input onFocus={this.handleShowPasswordConditions} value={this.state.updateUser.new_password} onBlur={this.handleHidePasswordConditions} type="password" name="new_password" onChange={(e) => this.handleOnChangeFields("new_password", e.target.value)} className="form-control" placeholder="Enter New Password" required />
                                                <div id="password" style={{ display: 'none' }}>
                                                    <small className="text-info">Password should contain</small><br />
                                                    <small>Atleast one Uppercase letter</small><br />
                                                    <small>Atleast one Special character letter</small><br />
                                                    <small>Atleast one Number letter</small><br />
                                                    <small>Minimum length of 8 and Maximum of 16</small><br />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-label"><strong>Confirm new Password</strong></label>
                                                <input type="password" name="confirm_new_password" value={this.state.updateUser.confirm_new_password} onChange={(e) => this.handleOnChangeFields("confirm_new_password", e.target.value)} className="form-control" placeholder="Enter New Password" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="reset" className="btn btn-primary btn-block" onClick={this.handleOnUpdatePassword} disabled={this.state.isUpdatingPassword}>{this.state.isUpdatingPassword ? 'Updating' : 'Update'}</button>
                                        <button type="button" className="d-none" id="update-password-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Update Logo Modal */}
                <div className="modal fade" id="updateLogoModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Update Logo</div>
                            <div className="modal-body">
                                <form onSubmit={(e) => { e.preventDefault(); this.handleUpdateOrganizationLogo() }}>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Logo of Merchant</strong></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadImage("organization_logo", e.target.files)} className="custom-file-input" required />
                                                        <label className="custom-file-label" id="organization_logo">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-primary btn-block" onClick={this.handleUpdateOrganizationLogo} disabled={this.state.isUpdatingOrganizationLogo}>{this.state.isUpdatingOrganizationLogo ? "Saving..." : "Save"}</button>
                                        <button type="button" className="d-none" id="update-logo-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: getUserData(state)
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MerchantProfile);