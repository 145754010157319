import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import swal from 'sweetalert';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import { getUserData } from '../../modules/reducers';
import apicaller from '../../util/ApiCaller';

class AccountsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account: {},
            phone: "",
            accountsList: [],
            isAddingAccount: false,
            isFetchingAccounts: false,
            AccountsFetched: false,
            selectedAccount: {},
            amount: 0,
            isLoading: false
        };
    }

    componentDidMount() {
        this.handleOnSearchAccounts();
    }

    handleOnChangeFields = (userField, value) => {
        let account = this.state.account;
        account[userField] = value
        this.setState({
            account
        })
    }

    validateAccountDetails(input) {
        let output = {};
        output.message = "";
        output.isvalid = true;
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const rifsc = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!input.name || input.name == "") {
            output.isvalid = false;
            output.message = "Please enter name";
            return output;
        }
        if (!input.contact || input.contact == "" || !rp.test(input.contact)) {
            output.isvalid = false;
            output.message = "Please enter a valid  phone number";
            return output;
        }
        if (!input.email || input.email == "" || !re.test(input.email)) {
            output.isvalid = false;
            output.message = "Please enter a valid email";
            return output;
        }
        if (!input.account_number || input.account_number == "") {
            output.isvalid = false;
            output.message = "Please enter account number";
            return output;
        }
        if (input.account_number != input.confirm_account_number) {
            output.isvalid = false;
            output.message = "Confirm account number does not match";
            return output;
        }
        if (!input.ifsc || input.ifsc == "" || !rifsc.test(input.ifsc)) {
            output.isvalid = false;
            output.message = "Please enter a valid ifsc code";
            return output;
        }
        return output;
    }

    handleOnAddAccount = () => {
        let account = this.state.account;
        let validation = this.validateAccountDetails(account);
        if (validation.isvalid) {
            this.setState({
                isAddingAccount: true
            })
            let data = {};
            data.userId = this.props.user._id;
            data.contact = {};
            data.account = {};
            data.contact.name = account.name;
            data.contact.email = account.email;
            data.contact.contact = account.contact;
            data.account.name = account.name;
            data.account.account_number = account.account_number;
            data.account.ifsc = account.ifsc;
            apicaller('post', 'accounts/add', data)
                .then(res => {
                    if (res.status == 200) {
                        document.getElementById("model-close-button").click();
                        this.handleOnSearchAccounts();
                    } else {
                        swal(res.data.data.message);
                    }
                    this.setState({
                        isAddingAccount: false
                    })
                })
                .catch((err) => {
                    this.setState({
                        isAddingAccount: false
                    })
                    swal(err.message);
                })
        }
        else {
            swal(validation.message, '', 'warning');
        }
    }

    validateBeforeSearch = (input) => {
        let output = {};
        output.message = "";
        output.isValid = true;
        const rp = /^[0-9]{10}$/g;
        if (!input.phone || input.phone == "" || !rp.test(input.phone)) {
            output.isValid = false;
            output.message = "Invalid mobile number, update your number in profile section.";
            return output;
        }
        return output;
    }

    handleOnSearchAccounts = () => {
        let data = {};
        data.userId = this.props.user._id;
        this.setState({
            isFetchingAccounts: true,
        })
        apicaller('post', 'accounts/get-by-userId', data)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.length > 0) {
                        this.setState({
                            accountsList: res.data.data,
                            isFetchingAccounts: false,
                            AccountsFetched: true,
                        })
                    }
                    else {
                        swal('No accounts found with the given number')
                        this.setState({
                            isFetchingAccounts: false,
                            accountsList: res.data.data,
                        })
                    }
                }
                else {
                    this.setState({
                        isFetchingAccounts: false,
                    })
                    swal('some internal error')
                }
            })
            .catch(err => {
                this.setState({
                    isFetchingAccounts: false,
                })
                swal(err);
            })
    }

    handlePayNow = (item) => {
        this.setState({
            selectedAccount: item
        })
        document.getElementById('pay-now-model-button').click();
    }

    verifyServiceDetails = (input) => {
        let output = {};
        output.isValid = true;
        output.message = "";
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!input.receiptName || input.receiptName == "") {
            output.isValid = false;
            output.message = "Please enter name";
            return output;
        }
        if (!input.receiptPhone || input.receiptPhone == "" || !rp.test(input.receiptPhone)) {
            output.isValid = false;
            output.message = "Please enter a valid phone number";
            return output;
        }
        if (!input.receiptEmail || input.receiptEmail == "" || !re.test(input.receiptEmail)) {
            output.isValid = false;
            output.message = "Please enter a valid email";
            return output;
        }
        if (!input.amount || input.amount == "" || input.amount <= 0) {
            output.isValid = false;
            output.message = "Please enter valid amount";
            return output;
        }
        return output;
    }

    handleOnSubmitPayment = () => {
        this.setState({
            isLoading: true
        })
        let data = {
            "amount": this.state.amount,
            "serviceType": this.props.location.state.selectedServiceShort,
            "receiptPhone": this.state.selectedAccount.phone,
            "receiptEmail": this.state.selectedAccount.email,
            "receiptName": this.state.selectedAccount.name
        };
        let validation = this.verifyServiceDetails(data);
        if (validation.isValid) {
            apicaller('post', `customerBiilingTransactions/customer/${this.props.user._id}/create_link`, data)
                .then(res => {
                    if (res.status == 200) {
                        window.open(res.data.data.payment_link.short_url);
                        document.getElementById('pay-now-model-close-button').click();
                        setTimeout(this.props.history.push(`/customer/dashboard`), 5000);
                    }
                    else {
                        swal("some internal issue")
                    }
                    this.setState({
                        isLoading: false
                    })
                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    swal('Error', err, 'error');
                })
        }
        else {
            this.setState({
                isLoading: false
            })
            swal(validation.message, "", "warning");
        }
    }

    render() {
        return (
            <div>
                <title>List Accounts</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Accounts"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">List of accounts</h4>
                                        <button type="button" className="btn light btn-primary" data-toggle="modal" data-target="#dmrAccountNumberModal" ><i className="zmdi zmdi-plus zmdi-hc-lg" /> Add New</button>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table className="table table-responsive-md">
                                                <thead>
                                                    <tr>
                                                        <th><strong>Name</strong></th>
                                                        {/* <th><strong>Email</strong></th> */}
                                                        <th><strong>Phone</strong></th>
                                                        <th><strong>Account Number</strong></th>
                                                        <th><strong>status</strong></th>
                                                        <th className="text-center"><strong>Actions</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.accountsList ? this.state.accountsList.map(item => (
                                                        <tr>
                                                            <td>{item.name}</td>
                                                            <td>{item.phone}</td>
                                                            <td>{item.bankAccount.account_number}</td>
                                                            <td>{item.accountStatus ? <span className="">Active</span> : <span className="">Inaction</span>}</td>
                                                            <td>
                                                                <div className="row">
                                                                    {this.props.location && this.props.location.state && this.props.location.state.selectedServiceShort ?
                                                                        <div className="col-md-6">
                                                                            <a style={{ cursor: 'pointer' }} className="dropdown-item text-success" onClick={() => this.handlePayNow(item)}>Pay Now</a>
                                                                        </div>
                                                                        : null}
                                                                    <div className="col-md-6">
                                                                        <a className="dropdown-item text-danger" style={{ cursor: 'pointer' }}>Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* add-account-model */}
                <div className="modal fade" id="dmrAccountNumberModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Name</strong></label>
                                            <input type="text" name="name" className="form-control" onChange={(e) => this.handleOnChangeFields("name", e.target.value)} value={this.state.account.name ? this.state.account.name : null} placeholder="Enter Name" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Phone Number</strong></label>
                                            <input type="text" name="contact" className="form-control" onChange={(e) => this.handleOnChangeFields("contact", e.target.value)} value={this.state.account.contact ? this.state.account.contact : null} placeholder="Enter Phone Number" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Email</strong></label>
                                            <input type="email" name="email" className="form-control" onChange={(e) => this.handleOnChangeFields("email", e.target.value)} value={this.state.account.email ? this.state.account.email : null} placeholder="Enter Email" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Account No</strong></label>
                                            <input type="text" name="account_number" className="form-control" onChange={(e) => this.handleOnChangeFields("account_number", e.target.value)} placeholder="Enter Account Number" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>Confirm Account No</strong></label>
                                            <input type="text" name="confirm_account_number" className="form-control" onChange={(e) => this.handleOnChangeFields("confirm_account_number", e.target.value)} placeholder="Confirm Account Number" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>IFSC Code</strong></label>
                                            <input type="text" name="ifsc" className="form-control" onChange={(e) => this.handleOnChangeFields("ifsc", e.target.value)} placeholder="Enter IFSC Code" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" onClick={this.handleOnAddAccount} className="btn btn-primary btn-block" disabled={this.state.isAddingAccount}>{this.state.isAddingAccount ? "Adding" : "Add"}</button>
                                    <button type="button" className="d-none" id="model-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" className="d-none" id="pay-now-model-button" data-toggle="modal" data-target="#payNowModal"></button>
                {/* <!-- pay now Modal --> */}
                <div className="modal fade" id="payNowModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form onSubmit={(e) => { e.preventDefault(); this.handleOnSubmitPayment() }}>
                                    <h4>{this.props.location && this.props.location.state && this.props.location.state.selectedService ? this.props.location.state.selectedService : null}</h4>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Amount to be collected</strong></label>
                                                <input type="number" name="amount" min="0" className="form-control" onChange={(e) => { this.setState({ amount: e.target.value }) }} placeholder="Enter Amount to be collected" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-block" disabled={this.state.isLoading}>{this.state.isLoading ? "Submitting" : "Submit"}</button>
                                        <button type="button" className="d-none" id="pay-now-model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: getUserData(state),
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AccountsList);