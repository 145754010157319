import React, { Component } from 'react';

class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <h1 className="text-center">EKRUPAY</h1>
                <div className="container" style={{ borderTopWidth: 1, borderColor: '#4620A0', borderStyle: 'solid' }}>
                </div>
                <div className="mt-3 container">
                    <h2>Privacy Policy</h2>
                    <h4>General</h4>
                    <p>
                        By using, accessing or participating in the Service, you agree to the terms of this privacy policy (the
                        "Privacy Policy"). Capitalized terms not defined in this Privacy Policy have the meanings set forth in the
                        Terms and Conditions, located here. We reserve the right to change our Privacy Policy at any time. If we do
                        this, we will post a notice that we have made changes to this Privacy Policy on the Website for at least 7
                        days after the changes are posted and will indicate at the bottom of the Privacy Policy the date these terms
                        were last revised. Any revisions to this Privacy Policy will become effective the earlier of (i) the end of
                        the foregoing 7-day period or (ii) the first time you access or use the Service after any such changes. If
                        you do not agree to abide by this Privacy Policy, you are not authorized to use, access or participate in
                        the Service.
        </p>
                    <h4>Information We Collect</h4>
                    <p>
                        When you use the Service you provide us with two types of information: (i) information you submit via the
                        Service and (ii) information regarding your use of the Service collected by us as you interacts with the
            Service.<br />
            When you enter the Website, we collect your browser type and IP address. This information is gathered for
            all Website visitors. In addition, we store certain information from your browser using "cookies." A cookie
            is a piece of data stored on the user's computer tied to information about the user. We use session ID
            cookies to confirm that users are logged in. If you do not want information collected through the use of
            cookies, there is a simple procedure in most browsers that allows you to deny or accept the cookie feature;
            however, you should note that cookies may be necessary to provide you certain features (e.g., customized
            delivery of information) available on the Website.
            Through the registration process you may provide us with your name, email address, hometown, and other
            information that may be requested during the registration process. When you use the Service you may submit
            information and content to your profile, generate Activity Data through engaging in educational activities
            on the Service, or send messages and otherwise transmit information to other users. We store this
            information so that we can provide you the Service and offer personalized features.</p>
                    <h4>User Of Information Obtained by EKRUPAY</h4>
                    <p>We may use your contact information to send you notifications regarding new services offered by EKRUPAY and its partners that we think you may find valuable. EKRUPAY may also send you
                    service-related announcements from time to time through the general operation of the Service. Generally, you
                    may opt out of such emails, although EKRUPAY reserves the right to send you notice about your
                    account even if you opt out of all voluntary email notifications.
                    Profile information is used by EKRUPAY primarily to be presented back to and edited by you when
                    you access the Service and to be presented to other users. In some cases, other users may be able to
            supplement your profile, including by submitting comments.<br />
            EKRUPAY may use aggregate or anonymous data collected through the Service, including Activity
            Data, for any purpose. This data may be used by EKRUPAY and shared with third parties in any
            manner.</p>

                    <h4>Sharing Your Personally-Identifiable Information with Third Parties</h4>
                    <p>EKRUPAY shares your personally-identifiable information only in limited circumstances where EKRUPAY believes such sharing is reasonably necessary to offer the Service, legally required or,
            permitted by you.</p>
                    <h4>For example:</h4>
                    <p>
                        We may provide personally-identifiable information to service providers who help us bring you the Service,
                        such as hosting the Service at a co-location facility or sending email updates. In connection with these
                        operations, our service providers may have access to personally-identifiable information for use for a
                        limited time. Where we utilize service providers for the processing of any of personally-identifiable
                        information, we implement reasonable contractual protections limiting the use of that
            personally-identifiable information to the provision of services to EKRUPAY.<br />
            Notwithstanding anything else in this policy, we may work with partners who use mobile SDKs, including the
            OneSignal Messaging SDK, to passively collect information (collectively, "SDK Information"), which generally
            helps us deliver personalized notifications. This data may also be used to identify you in a unique manner
            across other devices or browsers for the purposes of customizing ads or content. Depending on the
            permissions granted to this application, this information may include personally identifiable information
            (PII) including your e-mail address. This information may also include precise location (i.e. GPS-level
            data) or WiFi information, apps you have installed and enabled, and your mobile identifier (e.g., Android
            Advertising ID).
        </p>
                    <h4>Consumer Control & Opt-Out Options</h4>
                    <p>
                        1. Opting-out of OneSignal Push Notifications - You may in most cases opt out of receiving push
                        notifications by going to your device "Settings" and clicking on "Notifications," and then changing those
                        settings for some or all of the apps on your device. (Different device configurations, or updates to
            devices, may affect or change how these settings work.)<br />
            2. Opting Out of "Cross-App" Advertising on Mobile Devices - You can opt out of having your mobile
            advertising identifiers used for certain types of interest-based (also called "cross-app") mobile behavioral
            advertising, by accessing the "settings" on your Apple or Android mobile device, as follows: By opening the
            Google Settings app on your device, selecting Ads, and then selecting the option to opt-out of
            interest-based ads. (Different device configurations, or updates to devices, may affect or change how these
            settings work.)
            We may be required to disclose personally-identifiable information pursuant to lawful requests, such as
            subpoenas or court orders, or in compliance with applicable laws. Additionally, we may share account or
            other personally-identifiable information when we believe it is necessary to comply with law, to protect our
            interests or property, to prevent fraud or other illegal activity perpetrated through the Service or using
            the EKRUPAY name, or to prevent imminent harm. This may include sharing personally-identifiable
            information with other companies, lawyers, agents or government agencies.<br />
            If the ownership of all or substantially all of the EKRUPAY business, or individual business units
            or assets owned by EKRUPAY that are related to the Service, were to change, your
            personally-identifiable information may be transferred to the new owner. In any such transfer of
            information, your personally-identifiable information would remain subject to this section.
        </p>
                    <h4>Links</h4>
                    <p>
                        The Service may contain links to other websites. We are not responsible for the privacy practices of other
                        websites. We encourage users to be aware when they leave the Service to read the privacy statements of other
                        websites that collect personally identifiable information. This Privacy Policy applies solely to information
                        collected by EKRUPAY via the Service.
        </p>
                </div>
                {/* Footer Section */}
                <footer className="footer1 p-4" style={{ backgroundColor: "#E6DBFF" }}>
                    <div className="container d-flex">
                        <div class="copyright-txt text-center">
                            Copyright © EK RUPAY, All rights Reserved <br />
                            <small style={{ color: "#4620A0" }}><b>Powered By SVS COMMUNICATION</b></small>
                        </div>
                        <div className="ml-auto privacy-policy">
                            <a href="/privacy-policy" className="mr-3">Privacy Policy</a>
                            <a href="/terms-and-conditions" className="terms">Terms and Conditions</a>
                            <p className="text-center">Return to <a href="/" style={{ color: '#4620A0', fontWeight: 'bold' }}>HOME</a></p>
                        </div>
                    </div>
                </footer>
                {/* Footer Section */}
            </div>
        )
    }
}

export default PrivacyPolicy