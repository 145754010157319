import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from "redux";
import { connect } from "react-redux";
import { getisAuthenticated, getUserData } from '../../modules/reducers';
import { fetchUser } from '../../modules/actions';
import swal from 'sweetalert';

class SideBar extends Component {

    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.props.fetchUser(this.props.user._id);
        }
    }
    render() {
        return (
            <div>
                {/***********************************
            Sidebar start
        ************************************/}
                < div className="deznav" >
                    <div className="deznav-scroll">
                        <ul className="metismenu" id="menu">
                            {this.props.user.role == 2
                                ?
                                <li><a href="/customer/dashboard" className="ai-icon" aria-expanded="false">
                                    <i className="flaticon-381-networking" />
                                    <span className="nav-text">Dashboard</span>
                                </a>
                                </li> :
                                <li><a href="/merchant/dashboard" className="ai-icon" aria-expanded="false">
                                    <i className="flaticon-381-networking" />
                                    <span className="nav-text">Dashboard</span>
                                </a>
                                </li>
                            }
                            {this.props.user.role == 1 ?
                                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                    <i className="zmdi zmdi-code-setting zmdi-hc-4x" />
                                    <span className="nav-text">DMR</span>
                                </a>
                                    {this.props.user.isActive ?
                                        <ul aria-expanded="false">
                                            <li><a href="/dmr/transfer">Transfer Money</a></li>
                                        </ul>
                                        :
                                        <ul aria-expanded="false">
                                            <li><a onClick={() => { swal("Alert", "this service is unavailable", "error") }}>Transfer Money</a></li>
                                        </ul>
                                    }

                                </li>
                                : null}
                            {this.props.user.role == 2 ?
                                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                    <i className="zmdi zmdi-swap zmdi-hc-4x" />
                                    <span className="nav-text">Billing</span>
                                </a>
                                    <ul aria-expanded="false">
                                        {/* <li><a href="/customer/utility/trasactions">Utility</a></li> */}
                                        <li><a href="/customer/billing/trasactions">Transactions</a></li>
                                    </ul>
                                </li>
                                :
                                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                    <i className="zmdi zmdi-swap zmdi-hc-4x" />
                                    <span className="nav-text">Billing</span>
                                </a>
                                    <ul aria-expanded="false">
                                        {/* <li><a href="/merchant/utility/trasactions">Utility</a></li> */}
                                        <li><a href="/merchant/billing/trasactions">Transactions</a></li>
                                    </ul>
                                </li>}
                            {this.props.user.role == 1 ?
                                <li><a className="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                    <i className="zmdi zmdi-balance-wallet zmdi-hc-4x" />
                                    <span className="nav-text">Wallets</span>
                                </a>
                                    <ul aria-expanded="false">
                                        <li><a href="/wallet/transactions">Transactions</a></li>
                                    </ul>
                                </li>
                                : null}
                            {this.props.user.role == 2 ?
                                <li><a href="/accounts/list" className="ai-icon" aria-expanded="false">
                                    <i class="zmdi zmdi-accounts zmdi-hc-4x"></i>
                                    <span className="nav-text">Accounts</span>
                                </a>
                                </li>
                                : null}
                            {this.props.user.role == 2 ?
                                <li>
                                    <a href={`/customer/profile/${this.props.user._id}`} className="ai-icon" aria-expanded="false" >
                                        <i class="zmdi zmdi-account-circle zmdi-hc-4x"></i>
                                        <span className="nav-text">Profile</span>
                                    </a>
                                </li>
                                :
                                <li>
                                    <a href={`/merchant/profile/${this.props.user._id}`} className="ai-icon" aria-expanded="false">
                                        <i class="zmdi zmdi-account-circle zmdi-hc-4x"></i>
                                        <span className="nav-text">Profile</span>

                                    </a>
                                </li>}
                            <li><a href="/support" className="ai-icon" aria-expanded="false">
                                <i class="zmdi zmdi-help zmdi-hc-4x"></i>
                                <span className="nav-text">Help and support</span>
                            </a>
                            </li>
                        </ul>
                        <div className="copyright">
                            Copyright © {new Date().getFullYear()} EK RUPAY, All rights Reserved
                            {/* <p><strong>EK RUPAY</strong> ©All Rights Reserved</p> */}
                            {/* <p>by DexignZone</p> */}
                        </div>
                    </div>
                </div >
                {/***********************************
          Sidebar end
      ************************************/}
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        user: getUserData(state),
        isAuthenticated: getisAuthenticated(state),
    }
}

const mapDispatchToProps = dispatch => ({
    fetchUser: (userId) => dispatch(fetchUser(userId)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SideBar);
