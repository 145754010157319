import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';

class UserProfile extends Component {
    render() {
        return (
            <div>
                <title>User Profile</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Profile"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <h1>Profile Page</h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserProfile;