import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';
import apicaller from '../../util/ApiCaller';
import { connect } from "react-redux";
import { getUserData } from '../../modules/reducers';
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import swal from 'sweetalert';
import _ from 'lodash';
import { fetchWallets } from './helpers/DmrHelper';
import moment from 'moment';

class DmrTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: true,
            account: {},
            phone: "",
            accountsList: [],
            accountsListCopy: [],
            paymentDetails: {
                mode: "IMPS"
            },
            isProcessingTransfer: false,
            isAddingAccount: false,
            isFetchingAccounts: false,
            AccountsFetched: false,
            noAccountsAvailable: false,
            dmrDetails: {
                name: '',
                phone: '',
                email: '',
            },
            selectedAccount: {},
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            latestDmrTransaction: {}
        };
    }

    componentDidMount() {
        (async () => {
            let latestTransaction = await fetchWallets({
                query: {},
                pageSize: 1,
                pageNum: 1,
                startDate: this.state?.startDate,
                endDate: this.state?.endDate
            }, this.props.user._id);
            this.setState({
                latestDmrTransaction: latestTransaction[0]
            });
        })()
    }

    handleOnChangePhone = (value) => {
        let finalPhone = value?.replace(/ /g, '');
        this.setState({
            phone: finalPhone
        })
    }

    validateBeforeSearch = (input) => {
        let output = {};
        output.message = "";
        output.isValid = true;
        const rp = /^[0-9]{10}$/g;
        if (!input.phone || input.phone == "" || !rp.test(input.phone)) {
            output.isValid = false;
            output.message = "Please enter a valid  phone number";
            return output;
        }
        return output;
    }

    handleOnSearchAccounts = () => {
        let data = {};
        data.phone = this.state.phone;
        let validation = this.validateBeforeSearch(data);
        if (validation.isValid) {
            this.setState({
                isFetchingAccounts: true,
            })
            apicaller('post', 'accounts/get-by-phone', data)
                .then(res => {
                    if (res.status == 200) {
                        if (res.data.data.length > 0) {
                            this.setState({
                                accountsList: res.data.data,
                                accountsListCopy: res.data.data,
                                isFetchingAccounts: false,
                                noAccountsAvailable: false,
                                AccountsFetched: true,
                                dmrDetails: {
                                    name: res.data.data[0].name,
                                    phone: res.data.data[0].phone,
                                    email: res.data.data[0].email,
                                },
                                account: {
                                    contact: res.data.data[0].phone,
                                    email: res.data.data[0].email,
                                }
                            })
                        }
                        else {
                            swal('No accounts found with the given number');
                            document.getElementById('addAccountForm').reset();
                            this.setState({
                                isFetchingAccounts: false,
                                noAccountsAvailable: true,
                                AccountsFetched: false,
                                accountsList: res.data.data,
                                account: {
                                    contact: this.state.phone
                                }
                            })
                        }
                    }
                    else {
                        this.setState({
                            isFetchingAccounts: false,
                        })
                        swal('some internal error')
                    }
                })
                .catch(err => {
                    this.setState({
                        isFetchingAccounts: false,
                    })
                    swal(err);
                })
        }
        else {
            swal(validation.message, '', 'warning');
        }
    }

    handleOnChangeFields = (userField, value) => {
        if (userField == "ifsc") {
            value = value.toUpperCase();
        }
        let account = this.state.account;
        account[userField] = value
        this.setState({
            account
        })
    }

    handleOnChangePaymentFields = (userField, value) => {
        let paymentDetails = this.state.paymentDetails;
        paymentDetails[userField] = value
        this.setState({
            paymentDetails
        })
    }

    validateAccountDetails(input) {
        let output = {};
        output.message = "";
        output.isvalid = true;
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const rifsc = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!input.name || input.name == "") {
            output.isvalid = false;
            output.message = "Please enter name";
            return output;
        }
        if (!input.contact || input.contact == "" || !rp.test(input.contact)) {
            output.isvalid = false;
            output.message = "Please enter a valid  phone number";
            return output;
        }
        if (!input.email || input.email == "" || !re.test(input.email)) {
            output.isvalid = false;
            output.message = "Please enter a valid email";
            return output;
        }
        if (!input.account_number || input.account_number == "") {
            output.isvalid = false;
            output.message = "Please enter account number";
            return output;
        }
        if (input.account_number != input.confirm_account_number) {
            output.isvalid = false;
            output.message = "Confirm account number does not match";
            return output;
        }
        if (!input.ifsc || input.ifsc == "" || !rifsc.test(input.ifsc)) {
            output.isvalid = false;
            output.message = "Please enter a valid ifsc code";
            return output;
        }
        return output;
    };

    validateProfileDetails(input) {
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let output = {};
        output.message = "";
        output.isvalid = true;
        if (!input.name || input.name == "") {
            output.isvalid = false;
            output.message = "Please enter name";
            return output;
        }
        if (!input.contact || input.contact == "" || !rp.test(input.contact)) {
            output.isvalid = false;
            output.message = "Please enter a valid  phone number";
            return output;
        }
        if (!input.email || input.email == "" || !re.test(input.email)) {
            output.isvalid = false;
            output.message = "Please enter a valid email";
            return output;
        }
        return output;
    }

    handleOnAddAccount = () => {
        let account = this.state.account;
        let validation = this.validateAccountDetails(account);
        if (validation.isvalid) {
            this.setState({
                isAddingAccount: true
            })
            let data = {};
            data.userId = this.props.user._id;
            data.contact = {};
            data.account = {};
            data.contact.name = account.name;
            data.contact.email = account.email;
            data.contact.contact = account.contact;
            data.account.name = account.name;
            data.account.account_number = account.account_number;
            data.account.ifsc = account.ifsc;
            apicaller('post', 'accounts/add', data)
                .then(res => {
                    if (res.status == 200) {
                        document.getElementById("model-close-button").click();
                        this.handleOnSearchAccounts();
                    } else {
                    }
                    this.setState({
                        isAddingAccount: false
                    })
                })
                .catch((err) => {
                    this.setState({
                        isAddingAccount: false
                    })
                    swal(err.response.data.data.message);
                })
        }
        else {
            swal(validation.message, '', 'warning');
        }
    }

    createProfile = () => {
        let account = this.state.account;
        let validation = this.validateProfileDetails(account);
        if (validation.isvalid) {
            this.setState({
                isAddingAccount: true
            })
            let data = {};
            data.userId = this.props.user._id;
            data.contact = {};
            data.accounts = {};
            data.accounts.name = account.name;
            data.accounts.email = account.email;
            data.accounts.phone = account.contact;
            data.accounts.createdBy = this.props.user._id;
            apicaller('post', 'accountss/new', data)
                .then(res => {
                    if (res.status == 200) {
                        document.getElementById("create-profile-modal-close-button").click();
                        this.handleOnSearchAccounts();
                    } else {
                    }
                    this.setState({
                        isAddingAccount: false
                    })
                })
                .catch((err) => {
                    this.setState({
                        isAddingAccount: false
                    })
                    swal(err.response.data.data.message);
                })
        }
        else {
            swal(validation.message, '', 'warning');
        }
    }

    handlePayNow = (accountId, name, phone, selectedAccount) => {
        this.handleOnChangePaymentFields("accountId", accountId);
        this.handleOnChangePaymentFields("senderName", name);
        this.handleOnChangePaymentFields("senderPhone", phone);
        this.setState({
            selectedAccount
        })
        document.getElementById("model-open-button").click();
    }

    validateDmrTransfer = (input) => {
        let output = {};
        output.isValid = true;
        output.message = "";
        const rp = /^[0-9]{10}$/g;
        if (!input.senderName || input.senderName == "") {
            output.isValid = false;
            output.message = "Please enter sender name";
            return output;
        }
        if (!input.senderPhone || input.senderPhone == "" || !rp.test(input.senderPhone)) {
            output.isValid = false;
            output.message = "Please enter a valid sender phone number";
            return output;
        }
        if (!input.amount || input.amount == "") {
            output.isValid = false;
            output.message = "Please enter amount";
            return output;
        }
        if (input.amount > this.props.user.wallet_balance) {
            output.isValid = false;
            output.message = "Insufficient balance in wallet";
            return output;
        }
        if (!input.mode || input.mode == "") {
            output.isValid = false;
            output.message = "Please enter mode";
            return output;
        }
        return output;

    }

    completePayNow = (data) => {
        apicaller('post', 'dmr/initiate-payment', data)
            .then(res => {
                if (res.status == 200) {
                    swal('Processed', 'Your DMR Transaction was processed.', 'success');
                    setTimeout(() => {
                        this.props.history.push('/wallet/transactions');
                    }, 1500);
                }
                else {
                    swal('some internal issue');
                }
                this.setState({
                    isProcessingTransfer: false
                })
            })
            .catch(err => {
                this.setState({
                    isProcessingTransfer: false
                })
                swal('some internal issue');
            })
    }

    handleOnSubmitPayNow = () => {
        let data = this.state.paymentDetails;
        data.userId = this.props.user._id;
        let validation = this.validateDmrTransfer(data);
        if (validation.isValid) {
            this.setState({
                isProcessingTransfer: true
            })
            if (moment().diff(moment(this.state?.latestDmrTransaction?.createdAt), 'minutes') < 2 && this.state?.latestDmrTransaction?.amount == data?.amount) {
                swal('You have recently done a transaction with same amount. Are you sure you want to proceed?', {
                    buttons: {
                        cancel: 'Cancel',
                        confirm: "Confirm"
                    }
                })
                    .then(value => {
                        if (value && value != null) {
                            this.completePayNow(data);
                        }
                        else {
                            swal('Alert', 'Transaction Cancelled.', 'error');
                        }
                    })
            }
            else {
                this.completePayNow(data);
            }
        }
        else {
            swal(validation.message, '', 'warning');
        }
    }

    handleOnDeleteAccount = (accountId) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete this account?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    apicaller('post', `merchant/account/${accountId}/delete`, {})
                        .then(res => {
                            if (res.status == 200) {
                                swal("Deleted!", "Account has been deleted!", "success");
                                this.handleOnSearchAccounts();
                            }
                        })
                }
            });
    }

    render() {
        return (
            <div>
                <title>DMR Transfer</title>
                <div id="main-wrapper">
                    <TopBar
                        title="DMR"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div className="col-12 mt-4">
                                <div className="input-group search-area d-lg-inline-flex">
                                    <input type="text" className="form-control" onKeyUp={(e) => {
                                        if (e?.keyCode == 13) {
                                            console.log("Enter key is pressed");
                                            this.handleOnSearchAccounts();
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }} onChange={(e) => this.handleOnChangePhone(e.target.value)} placeholder="Mobile Number" value={this.state?.phone} />
                                    <div className="input-group-append">
                                        <span className="input-group-text" onClick={this.handleOnSearchAccounts} style={{ backgroundColor: '#40189D', color: '#ffffff' }}><i class="zmdi zmdi-arrow-right ml-2"></i></span>
                                    </div>
                                </div>
                            </div>
                            {this.state.AccountsFetched && this.state.accountsList.length > 0 ?
                                <div className="col-md-6 mt-5">
                                    <div class="card text-white bg-primary">
                                        <div class="card-header">
                                            <h5 class="card-title text-white">DMR Details</h5>
                                        </div>
                                        <div class="card-body mb-0">
                                            <p><b>Name: </b>{this.state.dmrDetails.name || ""}</p>
                                            <p><b>Phone: </b>{this.state.dmrDetails.phone || ""}</p>
                                            <p><b>Email: </b>{this.state.dmrDetails.email || ""}</p>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            <div className="col-lg-12 mt-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Available Accounts {this.state?.accountsList?.length > 0 ? `(${this.state?.accountsList?.length})` : ''}</h4>
                                        {this.state.phone.length >= 10 && this.state.AccountsFetched ?
                                            <button type="button" className="btn light btn-primary" data-toggle="modal" data-target="#dmrAccountNumberModal"><i className="zmdi zmdi-plus zmdi-hc-lg" /> Add New</button>
                                            : this.state.phone.length >= 10 && this.state.noAccountsAvailable ?
                                                <button type="button" className="btn light btn-primary" data-toggle="modal" data-target="#dmrCreateProfileModal"><i className="zmdi zmdi-plus zmdi-hc-lg" /> Create Profile</button>
                                                : null}
                                    </div>
                                    <div className="card-body">
                                        {this.state.accountsListCopy && this.state.accountsListCopy.length > 0 ?
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginBottom: '2rem'
                                            }}>
                                                <h5 style={{
                                                    marginTop: '10px'
                                                }}>Search:</h5> &nbsp;&nbsp;
                                                <input type="search" style={{
                                                    padding: '5px',
                                                    width: '35%'
                                                }} placeholder='Enter account number (or) name' onChange={(e) => {
                                                    let tempAccountsListCopy = [...this.state.accountsListCopy];
                                                    let searchText = `${e.target.value}`;
                                                    if (searchText != ``) {
                                                        tempAccountsListCopy = tempAccountsListCopy.filter(item => (item?.bankAccount?.account_number.toString().indexOf(searchText) >= 0 || _.kebabCase(item?.name).includes(_.kebabCase(searchText))));
                                                    }
                                                    this.setState({
                                                        accountsList: tempAccountsListCopy
                                                    })
                                                }} />
                                            </div>
                                            : null
                                        }
                                        <div className="table-responsive">
                                            <table className="table table-responsive-md">
                                                <thead>
                                                    <tr>
                                                        <th><strong>Name</strong></th>
                                                        {/* <th><strong>Email</strong></th> */}
                                                        <th><strong>Phone</strong></th>
                                                        <th><strong>Account Number</strong></th>
                                                        <th><strong>IFSC Code</strong></th>
                                                        <th><strong>status</strong></th>
                                                        <th className="text-center"><strong>Actions</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.accountsList ? this.state.accountsList.map(item => (
                                                        item.bankAccount ?
                                                            (
                                                                <tr>
                                                                    <td>{item.name}</td>
                                                                    {/* <td>{item.email}</td> */}
                                                                    <td>{item.phone}</td>
                                                                    <td>{item.bankAccount.account_number}</td>
                                                                    <td>{item.bankAccount.ifsc}</td>
                                                                    <td>{item.accountStatus ? <span className="">Active</span> : <span className="">Inaction</span>}</td>
                                                                    <td>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <a style={{ cursor: 'pointer' }} className="dropdown-item text-success" onClick={() => this.handlePayNow(item._id, item.name, item.phone, item)}>Pay Now</a>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <a className="dropdown-item text-danger" style={{ cursor: 'pointer' }} onClick={() => this.handleOnDeleteAccount(item._id)}>Delete</a>

                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="dropdown">
                                                                            <button type="button" className="btn btn-success light sharp" data-toggle="dropdown">
                                                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd"><rect x={0} y={0} width={24} height={24} /><circle fill="#000000" cx={5} cy={12} r={2} /><circle fill="#000000" cx={12} cy={12} r={2} /><circle fill="#000000" cx={19} cy={12} r={2} /></g></svg>
                                                                            </button>
                                                                            <div className="dropdown-menu">
                                                                                <a className="dropdown-item" onClick={() => this.handlePayNow(item._id)}>Pay Now</a>
                                                                                <a className="dropdown-item" href="#">Delete</a>
                                                                            </div>
                                                                        </div> */}
                                                                    </td>
                                                                </tr>
                                                            ) : null
                                                    )) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button data-toggle="modal" data-target="#payNowModal" className="d-none" id="model-open-button"></button>
                {/* add-account-model */}
                <div className="modal fade" id="dmrAccountNumberModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form onSubmit={(e) => e.preventDefault()} id="addAccountForm">
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Name</strong></label>
                                                <input type="text" name="name" className="form-control" onChange={(e) => this.handleOnChangeFields("name", e.target.value)} placeholder="Enter Name" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Phone Number</strong></label>
                                                <input type="text" name="contact" className="form-control" onChange={(e) => this.handleOnChangeFields("contact", e.target.value)} value={this.state.account.contact ? this.state.account.contact : null} placeholder="Enter Phone Number" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Email</strong></label>
                                                <input type="email" name="email" className="form-control" onChange={(e) => this.handleOnChangeFields("email", e.target.value)} value={this.state.account.email ? this.state.account.email : null} placeholder="Enter Email" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Account No</strong></label>
                                                <input type="text" name="account_number" className="form-control" onChange={(e) => this.handleOnChangeFields("account_number", e.target.value)} placeholder="Enter Account Number" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Confirm Account No</strong></label>
                                                <input type="text" name="confirm_account_number" className="form-control" onChange={(e) => this.handleOnChangeFields("confirm_account_number", e.target.value)} placeholder="Confirm Account Number" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>IFSC Code</strong></label>
                                                <input type="text" name="ifsc" className="form-control" value={this.state?.account?.ifsc} onChange={(e) => this.handleOnChangeFields("ifsc", e.target.value)} placeholder="Enter IFSC Code" required />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="text-center">
                                    <button type="submit" onClick={this.handleOnAddAccount} className="btn btn-primary btn-block" disabled={this.state.isAddingAccount}>{this.state.isAddingAccount ? "Adding" : "Add"}</button>
                                    <button type="button" className="d-none" id="model-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* create-profile-modal */}
                <div className="modal fade" id="dmrCreateProfileModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form onSubmit={(e) => e.preventDefault()} id="CreateProfileForm">
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Name</strong></label>
                                                <input type="text" name="name" className="form-control" onChange={(e) => this.handleOnChangeFields("name", e.target.value)} placeholder="Enter Name" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Email</strong></label>
                                                <input type="email" name="email" className="form-control" onChange={(e) => this.handleOnChangeFields("email", e.target.value)} value={this.state.account.email ? this.state.account.email : null} placeholder="Enter Email" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Phone Number</strong></label>
                                                <input type="text" name="contact" className="form-control" onChange={(e) => this.handleOnChangeFields("contact", e.target.value)} value={this.state.account.contact ? this.state.account.contact : null} placeholder="Enter Phone Number" required />
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Account No</strong></label>
                                                <input type="text" name="account_number" className="form-control" onChange={(e) => this.handleOnChangeFields("account_number", e.target.value)} placeholder="Enter Account Number" required />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Confirm Account No</strong></label>
                                                <input type="text" name="confirm_account_number" className="form-control" onChange={(e) => this.handleOnChangeFields("confirm_account_number", e.target.value)} placeholder="Confirm Account Number" required />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>IFSC Code</strong></label>
                                                <input type="text" name="ifsc" className="form-control" onChange={(e) => this.handleOnChangeFields("ifsc", e.target.value)} placeholder="Enter IFSC Code" required />
                                            </div>
                                        </div> */}
                                    </div>
                                </form>
                                <div className="text-center">
                                    <button type="submit" onClick={this.createProfile} className="btn btn-primary btn-block" disabled={this.state.isAddingAccount}>{this.state.isAddingAccount ? "Creating" : "Create"}</button>
                                    <button type="button" className="d-none" id="create-profile-modal-close-button" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* pay-now-modal */}
                <div className="modal fade" id="payNowModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form onSubmit={(e) => {
                                    e.preventDefault(); swal('Are you sure you want to proceed?', {
                                        buttons: {
                                            cancel: 'Cancel',
                                            confirm: "Confirm"
                                        }
                                    })
                                        .then(value => {
                                            if (value && value != null) {
                                                this.handleOnSubmitPayNow()
                                            }
                                            else {
                                                swal('Alert', 'Transaction Cancelled.', 'error');
                                            }
                                        })
                                }}>
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <label className="text-label"><strong>Name: </strong>{this.state.selectedAccount.name}</label><br />
                                            <label className="text-label"><strong>Account number: </strong>{this.state.selectedAccount && this.state.selectedAccount.bankAccount ? this.state.selectedAccount.bankAccount.account_number : ''}</label><br />
                                            <hr />
                                            <div className="form-group">
                                                <label className="text-label"><strong>Sender Name</strong></label>
                                                <input type="text" name="senderName" className="form-control" onChange={(e) => this.handleOnChangePaymentFields("senderName", e.target.value)} value={this.state.paymentDetails && this.state.paymentDetails.senderName ? this.state.paymentDetails.senderName : ''} placeholder="Enter Sender Name" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Sender Phone Number</strong></label>
                                                <input type="text" name="senderPhone" className="form-control" onChange={(e) => this.handleOnChangePaymentFields("senderPhone", e.target.value)} value={this.state.paymentDetails && this.state.paymentDetails.senderPhone ? this.state.paymentDetails.senderPhone : ''} placeholder="Enter Sender Phone Number" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Amount</strong></label>
                                                <input type="text" name="amount" className="form-control" onChange={(e) => this.handleOnChangePaymentFields("amount", e.target.value)} placeholder="Enter Amount" required />
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Select Payment Gateway</strong></label><br />
                                                <input type="radio" name="selectedGateway" id="pg1" required onChange={(e) => this.handleOnChangePaymentFields("gateway", "razorpay")} />&nbsp;&nbsp;
                                                <label className="text-label" for="pg1">Payment Gateway 1</label>
                                                <br />
                                                <input type="radio" name="selectedGateway" id="pg2" required onChange={(e) => this.handleOnChangePaymentFields("gateway", "easebuzz")} />&nbsp;&nbsp;
                                                <label className="text-label" for="pg2">Payment Gateway 2</label>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>Mode</strong></label>
                                                <select name="mode" className="form-control" onChange={(e) => this.handleOnChangePaymentFields("mode", e.target.value)}>
                                                    <option value="IMPS">IMPS</option>
                                                    <option value="IMPS">NEFT</option>
                                                    <option value="IMPS">RTGS</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-block" disabled={this.state.isProcessingTransfer}>{this.state.isProcessingTransfer ? "Transferring..." : "Transfer"}</button>
                                        <button type="button" className="d-none" id="model-close-button" data-dismiss="modal"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: getUserData(state),
    }
}

const mapDispatchToProps = dispatch => ({
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DmrTransfer);