import axios from "axios";
// export const BASE_URL = "http://192.168.97.143:3333/api/v1/";
// export const BASE_URL = "http://192.168.0.105:5551/api/v1/";
// export const BASE_URL = "http://192.168.31.116:5551/api/v1/";
export const BASE_URL = "https://api.ekrupay.in/api/v1/";
// export const BASE_URL = "https://devapi.ekrupay.in/api/v1/";
// export const BASE_URL = "https://e3a5-183-82-104-234.ngrok.io/api/v1/";

export default async function apicaller(method, route, data) {
    let url = BASE_URL + route;
    console.log(url);
    let api_response = await axios({
        method,
        url,
        data
    }, { withCredentials: true })
        .then((res) => {
            console.log("succ", res.data);
            return res;
        })
        .catch(err => {
            console.log("err", err);
            return err;
        })
    return api_response
}