import React, { Component } from 'react';
import _ from 'lodash';
import apicaller from '../../util/ApiCaller';

import { connect } from "react-redux";
import { logoutUser } from "../../modules/actions";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import swal from 'sweetalert';
import moment from 'moment';

class MerchantSignUp extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            smsSentStatus: '',
            isSendingOtp: false,
            isVerifyingOtp: false,
            isCreatingMerchant: false,
        }
    }

    componentDidMount() {
        this.props.logoutUser()
    }

    handleOnChangeFields = (userField, value) => {
        let user = this.state.user;
        user[userField] = value
        this.setState({
            user,
        })
    }

    handleOnUploadFiles = (userField, files) => {
        let value;
        for (var i in files) {
            if (i != "length" && i != "item") {
                document.querySelector(`#${userField}`).textContent = files[i].name;
                var FileSize = files[i].size / 1024 / 1024; // in MB
                if (FileSize > 3) {
                    swal('Alert', 'File size exceeds 3 MB', 'warning');
                }
                value = files[i];
            }
        }
        let user = this.state.user;
        user[userField] = value
        this.setState({
            user,
        })
    }

    //validation of user form
    validateUserFields(input) {
        let output = {};
        output.isValid = true;
        output.message = "";
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const rpass = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,16}$/
        if (!input.organization_name || input.organization_name == "") {
            output.isValid = false;
            output.message = "Please enter organization name";
            return output;
        }
        if (!input.first_name || input.first_name == "") {
            output.isValid = false;
            output.message = "Please enter first name";
            return output;
        }
        if (!input.phone || input.phone == "" || !rp.test(input.phone)) {
            output.isValid = false;
            output.message = "Please enter a valid phone number";
            return output;
        }
        if (!input.email || input.email == "" || !re.test(input.email)) {
            output.isValid = false;
            output.message = "Please enter a valid email";
            return output;
        }
        if (!input.dob || input.dob == "") {
            output.isValid = false;
            output.message = "Please enter date of birth";
            return output;
        }
        if (!input.shop_address || input.shop_address == "") {
            output.isValid = false;
            output.message = "Please enter shop address";
            return output;
        }
        if (!input.location || input.location == "") {
            output.isValid = false;
            output.message = "Please enter location";
            return output;
        }
        if (!input.organization_logo || !_.isEmpty(input.organization_logo)) {
            output.isValid = false;
            output.message = "Logo of Merchant is required";
            return output;
        }
        if (!input.organization_logo || (input.organization_logo.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Logo of Merchant should be less than 3MB";
            return output;
        }
        if (!input.location || input.location == "") {
            output.isValid = false;
            output.message = "Please enter location";
            return output;
        }
        if (!input.organization_outside_image || !_.isEmpty(input.organization_outside_image)) {
            output.isValid = false;
            output.message = "Shop outer photo is required";
            return output;
        }
        if (!input.organization_outside_image || (input.organization_outside_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Shop outer photo should be less than 3MB";
            return output;
        }
        if (!input.organization_inside_image || !_.isEmpty(input.organization_inside_image)) {
            output.isValid = false;
            output.message = "Shop inside photo is required";
            return output;
        }
        if (!input.organization_inside_image || (input.organization_inside_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Shop inside photo should be less than 3MB";
            return output;
        }
        if (!input.account_number || input.account_number == "") {
            output.isValid = false;
            output.message = "Please enter account number";
            return output;
        }
        if (!input.ifsc_code || input.ifsc_code == "") {
            output.isValid = false;
            output.message = "Please enter ifsc code";
            return output;
        }
        if (!input.account_holder_name || input.account_holder_name == "") {
            output.isValid = false;
            output.message = "Please enter account holder name";
            return output;
        }
        if (!input.canceled_check || !_.isEmpty(input.canceled_check)) {
            output.isValid = false;
            output.message = "Canceled Check is required";
            return output;
        }
        if (!input.canceled_check || (input.canceled_check.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Canceled Check should be less than 3MB";
            return output;
        }
        if (!input.pan_number || input.pan_number == "") {
            output.isValid = false;
            output.message = "Please enter pan number";
            return output;
        }
        if (!input.pan_image || !_.isEmpty(input.pan_image)) {
            output.isValid = false;
            output.message = "Pan card image is required";
            return output;
        }
        if (!input.pan_image || (input.pan_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Pan card image should be less than 3MB";
            return output;
        }
        if (!input.aadhar_number || input.aadhar_number == "") {
            output.isValid = false;
            output.message = "Please enter aadhar number";
            return output;
        }
        if (!input.aadhar_front_image || !_.isEmpty(input.aadhar_front_image)) {
            output.isValid = false;
            output.message = "Aadhar front image image is required";
            return output;
        }
        if (!input.aadhar_front_image || (input.aadhar_front_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Aadhar front image should be less than 3MB";
            return output;
        }
        if (!input.aadhar_back_image || !_.isEmpty(input.aadhar_back_image)) {
            output.isValid = false;
            output.message = "Aadhar back image image is required";
            return output;
        }
        if (!input.aadhar_back_image || (input.aadhar_back_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Aadhar back image should be less than 3MB";
            return output;
        }
        if (!input.certificate_image || !_.isEmpty(input.certificate_image)) {
            output.isValid = false;
            output.message = "Certificate image  is required";
            return output;
        }
        if (!input.certificate_image || (input.certificate_image.size / 1024 / 1024) > 3) {
            output.isValid = false;
            output.message = "Certificate image should be less than 3MB";
            return output;
        }
        if (!input.password || input.password == "" || !rpass.test(input.password)) {
            output.isValid = false;
            output.message = "Please check for minimum conditions and enter a password.";
            return output;
        }
        if (input.password != input.confirm_password) {
            output.isValid = false;
            output.message = "Password confirmation does not matched";
            return output;
        }
        return output;
    }

    handleOnSignup = () => {
        let user = this.state.user;
        let validation = this.validateUserFields(user);
        if (validation.isValid) {
            this.sendOtpToUser(user.phone, user.email);
        }
        else {
            swal(validation.message);
        }
    }

    sendOtpToUser = (phone, email) => {
        this.setState({
            isSendingOtp: true
        })
        apicaller('post', 'user/send/otp', { phone, email })
            .then(res => {
                if (res.status == 200) {
                    console.log("otp", res.data);
                    if (res.data.data.isUserExist) {
                        swal("User Exists", "User with same phone or email already exists in the system", "warning");
                    }
                    else {
                        // if (res.data.data.smsSentStatus.status == "success") {
                        //     this.setState({
                        //         smsSentStatus: res.data.data.smsSentStatus.status
                        //     })
                        // }
                        document.getElementById('model-button').click();
                        // else {
                        //     if (res.data.data.smsSentStatus.errors && res.data.data.smsSentStatus.errors[0].code == 51) {
                        //         swal("Please enter a valid number", "and try again!", "warning");
                        //     }
                        //     else {
                        //         swal("Something went wrong", "Please try again later!", "warning");
                        //     }
                        // }
                    }
                }
                this.setState({
                    isSendingOtp: false
                })
            })
            .catch(err => {
                swal('Error', 'something went wrong.', 'error');
                this.setState({
                    isSendingOtp: false
                })
            })
    }

    handleOnOtpSubmit = () => {
        let otp = this.state.user.otp;
        const rotp = /^[0-9]{4}$/g;
        if (otp && otp != "" && rotp.test(otp)) {
            this.verifyOtp(this.state.user.phone, otp);
        }
        else {
            swal('Alert', 'please enter otp', 'warning');
        }
    }

    verifyOtp = (phone, otp) => {
        this.setState({
            isVerifyingOtp: true
        })
        apicaller('post', 'user/verify/otp', { phone, otp })
            .then(res => {
                console.log("verify otp", res.data);
                if (res.status == 200) {
                    if (res.data.data.otp_record.isVerified) {
                        this.createMerchant();
                    }
                    else {
                        swal('Validation Error', 'OTP validation failed! please try again.', 'error')
                    }
                }
                this.setState({
                    isVerifyingOtp: false
                })
            })
            .catch(err => {
                swal('Error', 'something went wrong.', 'error');
                this.setState({
                    isVerifyingOtp: false
                })
            })
    }

    createMerchant = () => {
        this.setState({
            isCreatingMerchant: true
        })
        let user = this.state.user;
        let data = new FormData();
        data.append("organization_name", user.organization_name);
        data.append("first_name", user.first_name);
        data.append("last_name", user.last_name || "");
        data.append("email", user.email.toLowerCase());
        data.append("phone", user.phone);
        data.append("alternative_phone", user.alternative_phone || "");
        data.append("dob", user.dob);
        data.append("password", user.password);
        data.append("shop_address", user.shop_address);
        data.append("location", user.location);
        data.append("organization_logo", user.organization_logo);
        data.append("organization_outside_image", user.organization_outside_image);
        data.append("organization_inside_image", user.organization_inside_image);
        data.append("account_number", user.account_number);
        data.append("ifsc_code", user.ifsc_code);
        data.append("account_holder_name", user.account_holder_name);
        data.append("canceled_check", user.canceled_check);
        data.append("pan_number", user.pan_number);
        data.append("aadhar_number", user.aadhar_number);
        data.append("pan_image", user.pan_image);
        data.append("aadhar_front_image", user.aadhar_front_image);
        data.append("aadhar_back_image", user.aadhar_back_image);
        data.append("certificate_image", user.certificate_image);
        data.append("isKycCompleted", true);
        data.append("isKycProcessed", true);
        apicaller('post', 'merchant/register', data)
            .then(res => {
                console.log("merchant", res);
                if (res.status == 200) {
                    if (res.data.data.user) {
                        this.props.history.push('/merchant/login');
                    }
                    else {
                        swal('user creation failed')
                    }
                }
                this.setState({
                    isCreatingMerchant: false
                })
            })
            .catch(err => {
                this.setState({
                    isCreatingMerchant: false
                })
                swal('Error', 'something went wrong.', 'error');
            })
    }

    handleShowPasswordConditions = () => {
        let ele = document.getElementById('password');
        ele.style.display = 'block'
    }
    handleHidePasswordConditions = () => {
        let ele = document.getElementById('password');
        ele.style.display = 'none'
    }

    render() {
        return (
            <div className="container p-5">
                {/***********************************
            Content body start
        ************************************/}
                {/* row */}
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <h1 className="text-center">EKRUPAY</h1>
                        <h3 className="text-center mb-4" style={{ color: 'black' }}>Merchant Sign up</h3>
                        <div className="card" style={{ backgroundColor: '#4620A0', color: 'white' }}>
                            {/* <div className="card-header"> */}
                            {/* <h4 className="card-title" style={{ color: 'white' }}>Merchant Signup Form</h4> */}
                            {/* </div> */}
                            <div className="card-body">
                                {/* <form id="step-form-horizontal" className="step-form-horizontal"> */}
                                <form onSubmit={(e) => { e.preventDefault(); this.handleOnSignup() }}>
                                    <div>
                                        <h4 className="form_title text-white">Basic Details</h4>
                                        <section>
                                            <div className="row">
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Organization Name*</label>
                                                        <input type="text" name="organization_name" className="form-control" onChange={(e) => this.handleOnChangeFields("organization_name", e.target.value)} placeholder="Enter Organization Name" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Proprietor First Name*</label>
                                                        <input type="text" name="first_name" className="form-control" onChange={(e) => this.handleOnChangeFields("first_name", e.target.value)} placeholder="Proprietor First Name" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Proprietor Last Name</label>
                                                        <input type="text" name="last_name" className="form-control" onChange={(e) => this.handleOnChangeFields("last_name", e.target.value)} placeholder="Proprietor Last Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Phone Number*</label>
                                                        <input type="text" name="phone" className="form-control" onChange={(e) => this.handleOnChangeFields("phone", e.target.value)} placeholder="Enter Phone Number" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Alternate Phone Number</label>
                                                        <input type="text" name="alternative_phone" className="form-control" onChange={(e) => this.handleOnChangeFields("alternative_phone", e.target.value)} placeholder="Enter Alternate Phone Number" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Email*</label>
                                                        <input type="text" name="email" className="form-control" onChange={(e) => this.handleOnChangeFields("email", e.target.value)} placeholder="Enter Email" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Date of Birth*</label>
                                                        <input type="date" name="dob" max={moment().format('YYYY-MM-DD')} className="form-control" onChange={(e) => this.handleOnChangeFields("dob", e.target.value)} placeholder="Enter Date of Birth" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <h4 className="text-white">Shop Details</h4>
                                        <section>
                                            <div className="row">
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Shop Address*</label>
                                                        <input type="text" name="shop_address" className="form-control" onChange={(e) => this.handleOnChangeFields("shop_address", e.target.value)} placeholder="Enter Shop Address" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Location*</label>
                                                        <input type="text" name="location" className="form-control" onChange={(e) => this.handleOnChangeFields("location", e.target.value)} placeholder="Enter Location" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label className="text-label">Logo of Merchant*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("organization_logo", e.target.files)} required />
                                                            <label className="custom-file-label" id="organization_logo">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label className="text-label">Shop Outer Photos*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("organization_outside_image", e.target.files)} />
                                                            <label className="custom-file-label" id="organization_outside_image">Choose files</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label className="text-label">Shop Inner Photos*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("organization_inside_image", e.target.files)} />
                                                            <label className="custom-file-label" id="organization_inside_image">Choose files</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <h4 className="text-white">Bank Details</h4>
                                        <section>
                                            <div className="row">
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Account Number*</label>
                                                        <input type="text" name="account_number" className="form-control" onChange={(e) => this.handleOnChangeFields("account_number", e.target.value)} placeholder="Enter Account Number" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">IFSC Code*</label>
                                                        <input type="text" name="ifsc_code" className="form-control" onChange={(e) => this.handleOnChangeFields("ifsc_code", e.target.value)} placeholder="Enter IFSC Code" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Account Holder Name*</label>
                                                        <input type="text" name="account_holder_name" className="form-control" onChange={(e) => this.handleOnChangeFields("account_holder_name", e.target.value)} placeholder="Enter Account Holder Name" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label className="text-label">Upload canceled cheque*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("canceled_check", e.target.files)} required />
                                                            <label className="custom-file-label" id="canceled_check">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <h4 className="text-white">Documents to be uploaded</h4>
                                        <section>
                                            <div className="row">
                                                <div className="col-lg-6 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">PAN Card Number*</label>
                                                        <input type="text" name="pan_number" className="form-control" onChange={(e) => this.handleOnChangeFields("pan_number", e.target.value)} placeholder="Enter PAN Card Number" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label className="text-label">Upload PAN Card*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("pan_image", e.target.files)} required />
                                                            <label className="custom-file-label" id="pan_image">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Aadhaar Card Number*</label>
                                                        <input type="text" name="aadhar_number" className="form-control" onChange={(e) => this.handleOnChangeFields("aadhar_number", e.target.value)} placeholder="Enter Aadhaar Card Number" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label className="text-label">Upload Aadhaar Card Front Image*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("aadhar_front_image", e.target.files)} required />
                                                            <label className="custom-file-label" id="aadhar_front_image">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label className="text-label">Upload Aadhaar Card Back Image*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("aadhar_back_image", e.target.files)} required />
                                                            <label className="custom-file-label" id="aadhar_back_image">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label className="text-label">Certificates (Labour Licence/GST Licence/Certificate of incorporator)*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/x-png,image/jpeg" onChange={(e) => this.handleOnUploadFiles("certificate_image", e.target.files)} required />
                                                            <label className="custom-file-label" id="certificate_image">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Password*</label>
                                                        <input type="password" name="password" className="form-control" onBlur={this.handleHidePasswordConditions} onFocus={this.handleShowPasswordConditions} onChange={(e) => this.handleOnChangeFields("password", e.target.value)} placeholder="Enter Password" required />
                                                    </div>
                                                    <div id="password" style={{ display: 'none' }}>
                                                        <small className="text-info">Password should contain</small><br />
                                                        <small>Atleast one Uppercase letter</small><br />
                                                        <small>Atleast one Special character letter</small><br />
                                                        <small>Atleast one Number letter</small><br />
                                                        <small>Minimum length of 8 and Maximum of 16</small><br />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="text-label">Confirm Password*</label>
                                                        <input type="password" name="confirm_password" className="form-control" onChange={(e) => this.handleOnChangeFields("confirm_password", e.target.value)} placeholder="Confirm Password" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2 text-center">
                                                    <button type="submit" className="btn btn-primary btn-block" style={{ backgroundColor: '#FFFFFF', color: '#40189D', fontWeight: 'bold' }} disabled={this.state.isSendingOtp}>{this.state.isSendingOtp ? "Sending OTP, please wait..." : "Send OTP"}</button>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="d-none" id="model-button" data-toggle="modal" data-target="#exampleModalCenter"></button>

                    {/* Modal */}
                    <div className="modal fade" id="exampleModalCenter">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <label className="text-label"><strong>OTP</strong></label>
                                                <input type="text" name="otp" className="form-control" onChange={(e) => this.handleOnChangeFields("otp", e.target.value)} placeholder="Enter OTP" required />
                                                {this.state.smsSentStatus == "success" ? <small className="text-success">OTP sent to your phone number</small> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" onClick={this.handleOnOtpSubmit} className="btn btn-primary btn-block" disabled={(this.state.isVerifyingOtp || this.state.isCreatingMerchant)}>{this.state.isVerifyingOtp ? this.state.isCreatingMerchant ? "Signing up" : "Verifing" : "Verify and Submit"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/***********************************
            Content body end
        ************************************/}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MerchantSignUp);
