import React, { Component } from 'react';

class DataCard extends Component {
    render() {
        let cardColor = `card bg-${this.props.cardColor}`;
        let icon = `zmdi ${this.props.icon} ${this.props.iconSize}`;
        let iconColor = this.props.iconColor;
        return (
            <div className="col-xl-3 col-xxl-6 col-sm-6 wallet-balance-card">
                <div className={cardColor}>
                    <div className="card-body">
                        <div className="media align-items-center">
                            <span class="p-3 mr-3 border border-white rounded">
                                <i className={icon} style={{ color: iconColor }} />
                            </span>
                            <div className="media-body text-right">
                                <p className="fs-18 text-white mb-2">{this.props.cardText}</p>
                                <span className="fs-32 text-white font-w600">{this.props.cardValue}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default DataCard;