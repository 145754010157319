import React, { Component } from 'react';

class WalletCard extends Component {
    render() {
        let cardColor = `card text-white bg-${this.props.cardColor}`
        let cardCol = `${this.props.cardCol}`
        return (
            <div className={`${this.props.cardCol ? { cardCol } : "col-xl-6"} d-flex align-items-stretch`}>
                <div className={cardColor}>
                    <div className="card-header">
                        <h5 className="card-title text-white">{this.props.heading}</h5>
                    </div>
                    <div className="card-body mb-0">
                        <p className="card-text">{this.props.card_content}</p>
                        {this.props.showButton === "true" ?
                            <a onClick={this.props.handleOnClick} className="btn btn-primary light btn-card">{this.props.buttonText}</a>
                            : null}
                    </div>
                    <div className="card-footer bg-transparent border-0 text-white" style={{ fontSize: 28 }}>Balance: {this.props.amount}
                    </div>
                </div>
            </div>
        )
    }
}

export default WalletCard;