import swal from "sweetalert";
import apicaller from "../../../util/ApiCaller";

const fetchWallets = async (data, userId) => {
    let response = await apicaller('post', `merchant/${userId}/get-wallet-transactions`, data)
        .then(res => {
            if (res.status == 200) {
                let wallets = res.data.data.wallets;
                wallets.map(item => {
                    try {
                        item.razorpaySuccessResposne[0] = JSON.parse(item.razorpaySuccessResposne[0]);
                    } catch (error) {
                    }
                    finally {
                        return item;
                    }
                })
                return wallets
            }
            else {
                swal('some internal issue', '', 'error');
            }
        })
        .catch(err => {
            swal('Error', err, 'error');
        })
    return response;
}

export {
    fetchWallets,
}