import React, { useEffect, useState } from 'react'
import swal from 'sweetalert';
import apicaller from '../../../util/ApiCaller';
import { numberFormat } from '../../../App';
import moment from 'moment';
import { fetchWallets } from '../helpers/DmrHelper';

export default function DMRTransactionsTable(props) {
    const [startDate, setStartDate] = useState(moment().startOf('day'));
    const [endDate, setEndDate] = useState(moment().endOf('day'));
    const [wallets, setWallets] = useState([]);


    useEffect(() => {
        (async () => {
            let response = await fetchWallets(
                {
                    query: {},
                    pageSize: 5,
                    pageNum: 1,
                    startDate: startDate,
                    endDate: endDate
                }, props.userId
            );
            setWallets(response);
        })()
    }, [])


    return (
        <div>
            <div class="table">
                <table id="example4" class="display min-w850">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Transaction ID</th>
                            <th>UTR No.</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Transaction time</th>
                            <th>Type </th>
                            <th>Status </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr></tr>
                        {wallets ? wallets.map((item, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.transactionId}</td>
                                <td>{item.utr && item.utr != "" ? item.utr : item.razorpaySuccessResposne[0] && item.razorpaySuccessResposne[0].payload && item.razorpaySuccessResposne[0].payload.payout && item.razorpaySuccessResposne[0].payload.payout.entity && item.razorpaySuccessResposne[0].payload.payout.entity.utr ? item.razorpaySuccessResposne[0].payload.payout.entity.utr : '-'}</td>
                                <td>{item.senderName ? item.senderName : '-'}</td>
                                <td>{numberFormat(item.amount)}</td>
                                <td>{moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}</td>
                                <td>{item.transactionType}</td>
                                <td>
                                    {
                                        item.status && item.status != ""
                                            ?
                                            item.status
                                            :
                                            item.razorpayStatus ?
                                                item.razorpayStatus == "processing" ?
                                                    <span class="badge light text-info">{item.razorpayStatus}</span>
                                                    :
                                                    item.razorpayStatus == "reversed" ?
                                                        <span class="badge light text-danger">{item.razorpayStatus}</span>
                                                        :
                                                        item.razorpayStatus == "processed" ?
                                                            <span class="badge light text-success">{item.razorpayStatus}</span>
                                                            :
                                                            item.razorpayStatus == "refund" ?
                                                                <span class="badge light text-info">{item.razorpayStatus}</span>
                                                                : <span>{item.razorpayStatus}</span>
                                                :
                                                item.isSuccess ?
                                                    <span class="badge light badge-success">Success</span>
                                                    :
                                                    <span class="badge light text-danger">failed</span>
                                    }
                                </td>
                            </tr>
                        )) : <tr>No records available in table</tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
