import React, { Component } from 'react';
import SideBar from '../../components/global/Sidebar';
import TopBar from '../../components/global/Topbar';

class BillingTransactions extends Component {
    render() {
        return (
            <div>
                <title>Billing Transactions</title>
                <div id="main-wrapper">
                    <TopBar
                        title="Billing"
                    />
                    <SideBar />
                    <div class="content-body">
                        <div class="container-fluid">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Transactions</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table id="example4" class="display min-w850">
                                                <thead>
                                                    <tr>
                                                        <th>Roll No</th>
                                                        <th>Student Name</th>
                                                        <th>Invoice number</th>
                                                        <th>Fees Type </th>
                                                        <th>Payment Type </th>
                                                        <th>Status </th>
                                                        <th>Date</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>01</td>
                                                        <td>Tiger Nixon</td>
                                                        <td>#54605</td>
                                                        <td>Library</td>
                                                        <td>Cash</td>
                                                        <td><span class="badge light badge-success">Paid</span></td>
                                                        <td>2011/04/25</td>
                                                        <td><strong>120$</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BillingTransactions;