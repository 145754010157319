import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";

import Home from './screens/common/Home';
import IndexPage from './screens/common/IndexPage';
import Login from './screens/common/Login';
import SignUp from './screens/common/SignUp';
import MerchantSignUp from './screens/common/MerchantSignUp';
import Error from './screens/Error';
import CustomerDashboard from './screens/customer/CustomerDashboard';
import CustomerUtilityTransactions from './screens/customer/UtilityTransactions';
import CustomerBillingTransactions from './screens/customer/BillingTransactions';
import MerchantBillingTransactions from './screens/merchant/BillingTransactions';
import MerchantUtilityTransactions from './screens/merchant/UtilityTransactions';
import AccountsList from './screens/customer/AccountsList';
import UserProfile from './screens/common/UserProfile';
import UserSupport from './screens/common/UserSupport';
import WalletTransactions from './screens/merchant/WalletTransactions';
import DmrTransactions from './screens/merchant/DmrTransactions';
import DmrTransfer from './screens/merchant/DmrTransfer';
import CustomerProfile from './screens/customer/CustomerProfile';
import MerchantProfile from './screens/merchant/MerchantProfile';
import MerchantDashboard from './screens/merchant/MerchantDashboard';
// import { getisAuthenticated } from "./modules/reducers"
import { getisAuthenticated, getUserData } from "./modules/reducers"
import ForgotPassword from './screens/common/ForgotPassword';
import MerchantLogin from './screens/common/MerchantLogin';
import { fetchUser } from './modules/actions';
import PrivacyPolicy from './screens/common/PrivacyPolicy';
import TermsAndConditions from './screens/common/TermsAndConditions';
import MasterLink from './screens/merchant/MasterLink';

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);


class App extends Component {
  constructor() {
    super();
    this.state = {
    }
  }
  componentDidMount() {
    window.onpopstate = e => {
      window.location.reload();
    }
  }

  render() {
    return (
      <BrowserRouter forceRefresh={true}>
        <div>
          {/* <Navigation /> */}
          <Switch>
            <Route path="/" component={IndexPage} exact />
            <Route path="/customer/login" component={Login} exact />
            <Route path="/merchant/login" component={MerchantLogin} exact />
            <Route path="/customer/signup" component={SignUp} exact />
            <Route path="/merchant/signup" component={MerchantSignUp} exact />
            <Route path="/forgot/password" component={ForgotPassword} exact />
            <Route path="/privacy-policy" component={PrivacyPolicy} exact />
            <Route path="/terms-and-conditions" component={TermsAndConditions} exact />
            <Route path="/masterLink" component={MasterLink} exact />                  
            {/* <Route path="/index" component={IndexPage} exact /> */}
            {
              this.props.isAuthenticated ?
                <div>
                  <Route path="/customer/profile/:id" component={CustomerProfile} exact />
                  <Route path="/merchant/profile/:id" component={MerchantProfile} exact />
                  <Route path="/customer/dashboard" component={CustomerDashboard} exact />
                  <Route path="/merchant/dashboard" component={MerchantDashboard} exact />
                  <Route path="/customer/utility/trasactions" component={CustomerUtilityTransactions} exact />
                  <Route path="/customer/billing/trasactions" component={CustomerBillingTransactions} exact />
                  <Route path="/merchant/utility/trasactions" component={MerchantUtilityTransactions} exact />
                  <Route path="/merchant/billing/trasactions" component={MerchantBillingTransactions} exact />
                  <Route path="/merchant/billing/trasactions/:id" component={MerchantBillingTransactions} exact />
                  <Route path="/accounts/list" component={AccountsList} exact />
                  <Route path="/user/profile" component={UserProfile} exact />
                  <Route path="/support" component={UserSupport} exact />
                  <Route path="/wallet/transactions" component={WalletTransactions} exact />
                  {/* <Route path="/dmr/transactions" component={DmrTransactions} exact /> */}
                  <Route path="/dmr/transfer" component={DmrTransfer} exact />
                </div>
                : null
            }
            <Route component={Error} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: getisAuthenticated(state),
    user: getUserData(state),
  }
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(App);