import apicaller from '../util/ApiCaller';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGGING_IN_USER = 'LOGGING_IN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const POPULATE_ERROR = 'POPULATE_ERROR';
export const LOGGING_OUT_USER = 'LOGGING_OUT_USER';
export const FETCHING_USER = 'FETCHING_USER';
export const SET_USER_ID = 'SET_USER_ID';

export const loginUser = (data, role) => dispatch => {

    dispatch({
        type: LOGGING_IN_USER,
        status: true
    })
    apicaller('post', `users/${role}/login`, data)
        .then(res => {
            if (res.status === 200) {
                if (res.data.data.user) {
                    dispatch({
                        type: LOGIN_USER,
                        userData: res.data.data.user,
                        isAuthenticated: true
                    })
                }
                else {
                    dispatch({
                        type: POPULATE_ERROR,
                        error: res.data.data.error_message
                    })
                }
            }
            dispatch({
                type: LOGGING_IN_USER,
                status: false
            })
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: LOGGING_IN_USER,
                status: false
            })
            dispatch({
                type: POPULATE_ERROR,
                error: 'something went wrong'
            })
        })
}

export const logoutUser = () => dispatch => {
    dispatch({
        type: LOGGING_OUT_USER,
        status: true
    })
    dispatch({
        type: LOGOUT_USER
    })
    dispatch({
        type: LOGGING_OUT_USER,
        status: false
    })
}

export const fetchUser = (userId) => dispatch => {
    dispatch({
        type: FETCHING_USER,
        status: true
    })
    apicaller('get', `user/${userId}/profile`)
        .then(res => {
            if (res.status === 200) {
                if (res.data.data.user) {
                    dispatch({
                        type: LOGIN_USER,
                        userData: res.data.data.user,
                        isAuthenticated: true
                    })
                }
                else {
                    dispatch({
                        type: POPULATE_ERROR,
                        error: "some internal issue"
                    })
                }
            }
            dispatch({
                type: FETCHING_USER,
                status: false
            })
        })
        .catch(err => {
            dispatch({
                type: FETCHING_USER,
                status: false
            })
            dispatch({
                type: POPULATE_ERROR,
                error: 'something went wrong'
            })
            console.log(err);
        })
}
