import React, { Component } from 'react';
import { logoutUser } from '../../modules/actions';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { compose } from "redux";
import { getLogoutStatus, getUserData } from '../../modules/reducers';
import { numberFormat } from '../../App';
import apicaller from '../../util/ApiCaller';
import swal from 'sweetalert';


class TopBar extends Component {

    constructor() {
        super();
        this.state = {
            user: {},
        }
    }

    componentDidMount() {
        if (this.props.userData.organization_logo) {
            this.getImage("organization_logo", this.props.userData.organization_logo)
        }
    }

    handleLogout = () => {
        // this.props.logoutUser();
        this.props.history.push('/');
    }

    getImage = async (fieldName, fileKey) => {
        this.setState({
            user: this.props.userData
        })
        await apicaller('get', `get/file?fileKey=${fileKey}`)
            .then(res => {
                if (res.status == 200) {
                    let user = this.state.user;
                    let buf = Buffer.from(res.data);
                    let base64 = buf.toString('base64');
                    let final_image = `data:image/jpeg;base64,${base64}`
                    user[fieldName] = final_image
                    this.setState({ user, updateUser: user })
                }
                else {
                    swal('some internal issue');
                }
            })
    }


    render() {
        return (
            <div>
                {this.props.logoutStatus ?
                    <div id="preloader">
                        <div class="sk-three-bounce">
                            <div class="sk-child sk-bounce1"></div>
                            <div class="sk-child sk-bounce2"></div>
                            <div class="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                    :
                    <div>
                        {/***********************************
            Nav header start
        ************************************/}
                        <div className="nav-header">
                            {this.props.userData.role == 2 ?
                                <a href="/customer/dashboard" className="brand-logo">
                                    <h2 className="mx-auto" style={{ color: 'white', fontWeight: 'bold' }}>EK RUPAY</h2>
                                    {/* <img className="logo-abbr" src="/images/logo.png" alt="" />
                        <img className="logo-compact" src="/images/logo-text.png" alt="" />
                        <img className="brand-title" src="/images/logo-text.png" alt="" /> */}
                                </a>

                                :
                                <a href="/merchant/dashboard" className="brand-logo">
                                    <h2 className="mx-auto" style={{ color: 'white', fontWeight: 'bold' }}>EK RUPAY</h2>
                                    {/* <img className="logo-abbr" src="/images/logo.png" alt="" />
                                <img className="logo-compact" src="/images/logo-text.png" alt="" />
                                <img className="brand-title" src="/images/logo-text.png" alt="" /> */}
                                </a>
                            }
                            <div className="nav-control">
                                <div className="hamburger">
                                    <span className="line" /><span className="line" /><span className="line" />
                                </div>
                            </div>
                        </div>
                        {/***********************************
            Nav header end
        ************************************/}
                        {/***********************************
            Header start
        ************************************/}
                        <div className="header">
                            <div className="header-content">
                                <nav className="navbar navbar-expand">
                                    <div className="collapse navbar-collapse justify-content-between">
                                        <div className="header-left">
                                            <div className="dashboard_bar">
                                                {this.props.title}
                                            </div>
                                        </div>
                                        <ul className="navbar-nav header-right">
                                            {this.props.userData && this.props.userData.role == 1 ?
                                                <li className="nav-item">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <a href="/wallet/transactions" className="dropdown-item ai-icon">
                                                                <i class="zmdi zmdi-balance-wallet"></i>
                                                                <span className="ml-2">Wallet: {numberFormat(this.props.userData.wallet_balance)} </span>
                                                            </a>
                                                        </div>
                                                        {/* <div className="col-md-6">
                                                            <a href="/wallet/transactions" className="dropdown-item ai-icon">
                                                                <i class="zmdi zmdi-balance-wallet"></i>
                                                                <span className="ml-2">PG Wallet: {numberFormat(this.props.userData.pg_balance)} </span>
                                                            </a>
                                                        </div> */}
                                                    </div>
                                                </li>
                                                : null}
                                            {/* <li className="nav-item dropdown notification_dropdown">
                                                <a className="nav-link  ai-icon" href="#" role="button" data-toggle="dropdown">
                                                    <svg width={26} height={28} viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.45251 25.6682C10.0606 27.0357 11.4091 28 13.0006 28C14.5922 28 15.9407 27.0357 16.5488 25.6682C15.4266 25.7231 14.2596 25.76 13.0006 25.76C11.7418 25.76 10.5748 25.7231 9.45251 25.6682Z" fill="#3E4954" />
                                                        <path d="M25.3531 19.74C23.8769 17.8785 21.3995 14.2195 21.3995 10.64C21.3995 7.09073 19.1192 3.89758 15.7995 2.72382C15.7592 1.21406 14.5183 0 13.0006 0C11.4819 0 10.2421 1.21406 10.2017 2.72382C6.88095 3.89758 4.60064 7.09073 4.60064 10.64C4.60064 14.2207 2.12434 17.8785 0.647062 19.74C0.154273 20.3616 0.00191325 21.1825 0.240515 21.9363C0.473484 22.6721 1.05361 23.2422 1.79282 23.4595C3.08755 23.8415 5.20991 24.2715 8.44676 24.491C9.84785 24.5851 11.3543 24.64 13.0007 24.64C14.646 24.64 16.1524 24.5851 17.5535 24.491C20.7914 24.2715 22.9127 23.8415 24.2085 23.4595C24.9477 23.2422 25.5268 22.6722 25.7597 21.9363C25.9983 21.1825 25.8448 20.3616 25.3531 19.74Z" fill="#3E4954" />
                                                    </svg>
                                                    <span className="badge light text-white bg-primary rounded-circle">0</span>
                                                </a> */}
                                            {/* <div className="dropdown-menu dropdown-menu-right">
                                                    <div id="DZ_W_Notification1" className="widget-media dz-scroll p-3 height380">
                                                        <ul className="timeline">
                                                            <li>
                                                                <div className="timeline-panel">
                                                                    <div className="media mr-2">
                                                                        <img alt="image" width={50} src="images/avatar/1.jpg" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="mb-1">Dr sultads Send you Photo</h6>
                                                                        <small className="d-block">29 July 2020 - 02:26 PM</small>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="timeline-panel">
                                                                    <div className="media mr-2 media-info">
                                                                        KG
                              </div>
                                                                    <div className="media-body">
                                                                        <h6 className="mb-1">Resport created successfully</h6>
                                                                        <small className="d-block">29 July 2020 - 02:26 PM</small>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="timeline-panel">
                                                                    <div className="media mr-2 media-success">
                                                                        <i className="fa fa-home" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="mb-1">Reminder : Treatment Time!</h6>
                                                                        <small className="d-block">29 July 2020 - 02:26 PM</small>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="timeline-panel">
                                                                    <div className="media mr-2">
                                                                        <img alt="image" width={50} src="images/avatar/1.jpg" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="mb-1">Dr sultads Send you Photo</h6>
                                                                        <small className="d-block">29 July 2020 - 02:26 PM</small>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="timeline-panel">
                                                                    <div className="media mr-2 media-danger">
                                                                        KG
                              </div>
                                                                    <div className="media-body">
                                                                        <h6 className="mb-1">Resport created successfully</h6>
                                                                        <small className="d-block">29 July 2020 - 02:26 PM</small>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="timeline-panel">
                                                                    <div className="media mr-2 media-primary">
                                                                        <i className="fa fa-home" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="mb-1">Reminder : Treatment Time!</h6>
                                                                        <small className="d-block">29 July 2020 - 02:26 PM</small>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <a className="all-notification" href="#">See all notifications <i className="ti-arrow-right" /></a>
                                                </div>
                                             */}
                                            {/* </li> */}
                                            <li className="nav-item dropdown header-profile">
                                                <a className="nav-link" href="#" role="button" data-toggle="dropdown">
                                                    <img src={this.state.user.organization_logo ? this.state.user.organization_logo : null} width={20} alt="" />
                                                    <div className="header-info">
                                                        <span className="text-black">{this.props.userData && this.props.userData.first_name ? this.props.userData.first_name : null} {this.props.userData && this.props.userData.last_name ? this.props.userData.last_name : null}</span>
                                                        <p className="fs-12 mb-0">{this.props.userData.userId}</p>
                                                        <p className="fs-12 mb-0">{this.props.userData && this.props.userData.role ?
                                                            this.props.userData.role == 2 ? "Customer" :
                                                                this.props.userData.role == 1 ? "Merchant" :
                                                                    "Admin"
                                                            : null}</p>
                                                    </div>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    {
                                                        this.props.userData && this.props.userData.role == 2 ?
                                                            <Link className="dropdown-item ai-icon" to={{
                                                                pathname: `/customer/profile/${this.props.userData._id}`,
                                                                state: { userId: this.props.userData._id }
                                                            }}>
                                                                <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                                                <span className="ml-2">Profile </span>
                                                            </Link> :
                                                            <Link className="dropdown-item ai-icon" to={{
                                                                pathname: `/merchant/profile/${this.props.userData._id}`,
                                                                state: { userId: this.props.userData._id }
                                                            }}>
                                                                <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                                                <span className="ml-2">Profile </span>
                                                            </Link>
                                                    }
                                                    <a onClick={this.handleLogout} className="dropdown-item ai-icon">
                                                        <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1={21} y1={12} x2={9} y2={12} /></svg>
                                                        <span className="ml-2">Logout </span>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        {/***********************************
            Header end ti-comment-alt
        ************************************/}
                    </div>}
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        logoutStatus: getLogoutStatus(state),
        userData: getUserData(state),
    }
}

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser())
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TopBar);

