import React, { Component } from 'react';

import { connect } from "react-redux";
import { logoutUser } from "../../modules/actions";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";

export class IndexPage extends Component {

    componentDidMount() {
        this.props.logoutUser()
    }

    render() {
        return (
            <div style={{ color: '#fff' }}>
                <title>EKRUPAY</title>
                <link href="css/home.css" rel="stylesheet" />
                <button type="button" className="mobile-nav-toggle d-lg-none">
                    <div className="hamburger">
                        <span className="line" />
                        <span className="line" />
                        <span className="line" />
                    </div>
                </button>
                <header id="header" className="fixed-top d-flex align-items-center">
                    <div className="container d-flex align-items-center">
                        <div className="logo mr-auto">
                            <h1 className="text-light"><a href="/index"><span>EkRupay</span></a></h1>
                        </div>
                        <nav className="nav-menu d-none d-lg-block">
                            <ul>
                                <li className="active"><a href="#index">Home</a></li>
                                <li><a href="#about">About</a></li>
                                <li><a href="#services">Services</a></li>
                                <li><a href="#contact">Contact</a></li>
                                <li><a href="/merchant/login">Login</a></li>
                                <li><a href="/merchant/signup">Sign Up</a></li>
                                {/* <li>
                                    <div className="dropdown">
                                        <a role="button" className="btn-get-started" data-toggle="dropdown" aria-expanded="false">
                                            Login
                                        </a>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="/customer/login">Customer</a>
                                            <a className="dropdown-item" href="/merchant/login">Merchant</a>
                                        </div>
                                    </div>
                                </li> */}
                                {/* <li>
                                    <a role="button" className="btn-get-started" data-toggle="dropdown" aria-expanded="false">
                                        Sign Up
                                    </a>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item" href="/customer/signup">Customer</a>
                                        <a className="dropdown-item" href="/merchant/signup">Merchant</a>
                                    </div>
                                </li> */}
                            </ul>
                        </nav>
                    </div>
                </header>
                {/* Home Section */}
                <section id="hero" className="d-flex align-items-center">
                    <div className="container position-relative">
                        <div className="row justify-content-center">
                            <div className="col-xl-10 col-lg-9 text-center">
                                <h1>One Web Application to Serve all your Payments</h1>
                                <h2>Related to utility and billing services</h2>
                            </div>
                        </div>
                        <div className="text-center">
                            <a href="#about" className="btn-get-started">Get Started</a>
                        </div>
                    </div>
                </section>
                {/* Home Section */}
                {/* About Section */}
                <section className="about" id="about">
                    <div className="container">
                        <div className="section-title">
                            <h2>About Us</h2>
                            <p>Simplicity is fundamental quality which our customers associate in EKRUPAY. It is the seamless solution for all your Payment requirements. EKRUPAY is designed to meet the unique and varying needs of billing / utility services.</p>
                        </div>
                    </div>
                </section>
                {/* About Section */}
                {/* Services Section */}
                <section id="services" className="d-flex align-items-center">
                    <div className="container position-relative">
                        <div className="section-title">
                            <h2>Our Services</h2>
                            <p>We serve for various online payment related services</p>
                        </div>
                        <div className="row icon-boxes d-flex justify-content-center">
                            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src="/images/building.svg" width={40} height={40} />
                                    </div>
                                    <h4 className="title"><a href>Rental payments</a></h4>
                                    <p className="description">Get rid of all kinds of rental payment issue with EKRUPAY. Here we would be able to offer you high-quality Rental payments services,property token,Property deposit,office/shop/shed rent,society maintenance with which you will never miss your next rental payment ever.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src="/images/books.svg" width={40} height={40} />
                                    </div>
                                    <h4 className="title"><a href>Intitutional Payments</a></h4>
                                    <p className="description">The fee collection services offered by EKRUPAY is a one set solution for all kinds of educational institutes. We will take care of all your school fee payments efficiently.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src="/images/grocery.svg" width={40} height={40} />
                                    </div>
                                    <h4 className="title"><a href>Grocery Bill Payments</a></h4>
                                    <p className="description">Choose and Buy Grocery from a wide range of fruits, vegetables, baby care products, personal care products and much more. Shop from any grocery shop or supermarket. Pay bill from here.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src="/images/utilities.svg" width={40} height={40} />
                                    </div>
                                    <h4 className="title"><a href>Utility Bill Payments</a></h4>
                                    <p className="description">EKRUPAY provides features to recharge online, pay bills online. Avail Prepaid Mobile Recharge, DTH Recharge, etc. Pay Utility Bills Online - Mobile bills, Electricity Bills, Gas Bills, Landline Bills, etc.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Services Section */}
                {/* Contact Section */}
                <section className="contact" id="contact">
                    <div className="container">
                        <div className="section-title">
                            <h2>Contact us</h2>
                            <p>To know more information about EKRUPAY contact us</p>
                        </div>
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-10">
                                <div className="info-wrap">
                                    <div className="row">
                                        <div className="col-lg-4 info">
                                            <i className="flaticon-381-location" />
                                            <h4>Location:</h4>
                                            <p>Plot NO 13,Ganesh Nagar,Chintal<br />Medchal</p>
                                        </div>
                                        <div className="col-lg-4 info mt-4 mt-lg-0">
                                            <i className="fa fa-envelope" aria-hidden="true" />
                                            <h4>Email:</h4>
                                            <p>ekrupay1@gmail.com</p>
                                        </div>
                                        <div className="col-lg-4 info mt-4 mt-lg-0">
                                            <i className="fa fa-phone" aria-hidden="true" />
                                            <h4>Call:</h4>
                                            <p>9390005464<br /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Contact Section */}
                {/* Footer Section */}
                <footer className="footer1">
                    <div className="container d-flex">
                        <div class="copyright-txt text-center">
                            Copyright © EK RUPAY, All rights Reserved <br />
                            <small style={{ color: "#4620A0" }}><b>Powered By SVS COMMUNICATION</b></small>
                        </div>
                        <div className="ml-auto privacy-policy">
                            <a href="/privacy-policy" className="mr-3">Privacy Policy</a>
                            <a href="/terms-and-conditions" className="terms">Terms and Conditions</a>
                        </div>
                    </div>
                </footer>
                {/* Footer Section */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(IndexPage);