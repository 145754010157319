import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import makeAnimated from 'react-select/animated';
import '../../assets/index.css';
import _ from 'lodash';
import { getUserData } from '../../modules/reducers';
import swal from 'sweetalert';
import apicaller from '../../util/ApiCaller';
import queryString from 'query-string';



class MasterLink extends Component {

    constructor() {
        super();
        this.state = {
            serviceDetails: {},
            userId: '',
            user: {}
        }
    }

    componentDidMount() {
        this.setState({
            userId: queryString.parse(this.props.location.search).id
        }, () => {
            this.fetchUserProfileDetails(this.state?.userId);
        })
    }

    handleOnChangeFields = (Field, value) => {
        let serviceDetails = this.state.serviceDetails;
        if (Field == "receiptEmail") {
            value = value.replace(/ /g, "");
        }
        serviceDetails[Field] = value
        this.setState({
            serviceDetails,
        })
    }

    verifyServiceDetails = (input) => {
        let output = {};
        output.isValid = true;
        output.message = "";
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!input.receiptName || input.receiptName == "") {
            output.isValid = false;
            output.message = "Please enter name";
            return output;
        }
        if (!input.receiptPhone || input.receiptPhone == "" || !rp.test(input.receiptPhone)) {
            output.isValid = false;
            output.message = "Please enter a valid phone number";
            return output;
        }
        if (!input.receiptEmail || input.receiptEmail == "" || !re.test(input.receiptEmail)) {
            output.isValid = false;
            output.message = "Please enter a valid email";
            return output;
        }
        if (!input.amount || input.amount == "" || input.amount <= 0) {
            output.isValid = false;
            output.message = "Please enter valid amount";
            return output;
        }
        return output;
    }

    handleOnSubmitPayment = () => {
        if (this.state?.user?.isActive) {
            this.setState({
                isLoading: true
            })
            let serviceDetails = this.state.serviceDetails;
            let data = {
                "amount": serviceDetails.amount,
                "serviceType": this.state.selectedServiceShort,
                "receiptPhone": serviceDetails.receiptPhone,
                "receiptEmail": serviceDetails.receiptEmail,
                "receiptName": serviceDetails.receiptName,
                // "gateway": serviceDetails.gateway,
            };
            let validation = this.verifyServiceDetails(data);
            if (validation.isValid) {
                apicaller('post', `pgTransactions/merchant/${this.state.userId}/create_link`, data)
                    .then(res => {
                        if (res.status == 200) {
                            if (res?.data?.data?.errorCode?.code) {
                                if (res?.data?.data?.errorCode?.code == 1) {
                                    swal("try another service")
                                }
                                else {
                                    swal("some internal issue")
                                }
                            }
                            else {
                                let a = document.createElement("a");
                                document.body.appendChild(a);
                                a.style = "display: none";
                                // window.close();
                                if (res.data.data.payment_link.short_url) {
                                    a.href = res.data.data.payment_link.short_url;
                                }
                                else {
                                    a.href = res.data.data.payment_link;
                                }
                                a.click();
                                document.body.removeChild(a);

                            }
                        }
                        else {
                            swal("some internal issue")
                        }
                        setTimeout(() => {
                            this.setState({
                                isLoading: false
                            })
                        }, 1500);
                    })
                    .catch(err => {
                        this.setState({
                            isLoading: false
                        })
                        swal('Error', err, 'error');
                    })
            }
            else {
                this.setState({
                    isLoading: false
                })
                swal(validation.message, "", "warning");
            }
        }
        else {
            swal("This merchant is inactive, transactions are disabled.", "", "warning");
        }
    }

    fetchUserProfileDetails = (userId) => {
        apicaller('get', `user/${userId}/profile`)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.user) {
                        this.setState({
                            user: res.data.data.user
                        }, () => {
                            this.downloadUserImages();
                        })
                    }
                    else {
                        swal('Error', 'some internal issue', 'error');
                    }
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
            .catch(err => {
                swal('Error', err.message, 'error');
            })
    }

    downloadUserImages = async () => {
        if (this.state.user.organization_logo) {
            await this.getImage("organization_logo", this.state.user.organization_logo)
        }
    }

    getImage = async (fieldName, fileKey) => {
        await apicaller('get', `get/file?fileKey=${fileKey}`)
            .then(res => {
                if (res.status == 200) {
                    let user = this.state.user;
                    let buf = Buffer.from(res.data);
                    let base64 = buf.toString('base64');
                    let final_image = `data:image/jpeg;base64,${base64}`
                    user[fieldName] = final_image
                    this.setState({ user, updateUser: user })
                }
                else {
                    swal('Error', 'some internal issue', 'error');
                }
            })
    }

    render() {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundColor:'#32147C50',
                    padding: '20px 0px'
                }}>
                    <div>
                        <img src={this.state?.user?.organization_logo} height={80} width={80} style={{
                            borderRadius: '20px'
                        }} />
                    </div>
                    <div className='ml-4'>
                        <h2 style={{
                            color: '#32147C',
                            fontWeight: 'bold'
                        }}>{this.state?.user?.organization_name}</h2>
                    </div>
                </div>
                <div className='container mt-2'>
                    {/* <h3>Master Link for all services.</h3> */}
                    <form onSubmit={(e) => { e.preventDefault(); this.handleOnSubmitPayment() }}>
                        <h4>{this.state.selectedService}</h4>
                        <div className="row">
                            <div className="col-lg-12 mb-2">
                                <div className="form-group">
                                    <label className="text-label"><strong>Service</strong></label>
                                    <select className='form-control' required onChange={(e) => {
                                        this.setState({
                                            selectedServiceShort: e.target.value
                                        })
                                    }}>
                                        <option value={""} selected disabled>{'<-- Select Option-->'}</option>
                                        <option value={"RP"}>Rental Bill Payment</option>
                                        <option value={"GP"}>Grocery Bill Payment</option>
                                        <option value={"IP"}>Institutional Fee Payment</option>
                                    </select>
                                    <label className="text-label"><strong>Name</strong></label>
                                    <input type="text" name="receiptName" className="form-control" onChange={(e) => this.handleOnChangeFields("receiptName", e.target.value)} placeholder="Enter Name" required />
                                    <label className="text-label"><strong>Email</strong></label>
                                    <input type="email" name="receiptEmail" value={this.state?.serviceDetails?.receiptEmail} className="form-control" onChange={(e) => this.handleOnChangeFields("receiptEmail", e.target.value)} placeholder="Enter Email" required />
                                    <label className="text-label"><strong>Phone Number</strong></label>
                                    <input type="text" name="receiptPhone" className="form-control" onChange={(e) => this.handleOnChangeFields("receiptPhone", e.target.value)} placeholder="Enter Phone Number" required />
                                    <label className="text-label"><strong>Amount to be collected</strong></label>
                                    <input type="number" name="amount" min="0" className="form-control" onChange={(e) => this.handleOnChangeFields("amount", e.target.value)} placeholder="Enter Amount to be collected" required />
                                    {/* <label className="text-label"><strong>Select Payment Gateway</strong></label><br />
                                <input type="radio" name="selectedGateway" id="pg1" required onChange={(e) => this.handleOnChangeFields("gateway", "razorpay")} />&nbsp;&nbsp;
                                <label className="text-label" for="pg1">Payment Gateway 1</label>
                                <br />
                                <input type="radio" name="selectedGateway" id="pg2" required onChange={(e) => this.handleOnChangeFields("gateway", "easebuzz")} />&nbsp;&nbsp;
                                <label className="text-label" for="pg2">Payment Gateway 2</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary btn-block" disabled={this.state.isLoading || !this.state?.user?.isActive}>{this.state.isLoading ? "Submitting" : "Submit"}</button>
                            <small className='text-danger'>{!this.state?.user?.isActive ? "This merchant is inactive, transactions are disabled." : ''}</small>
                            {/* <button type="button" className="d-none" id="model-close-button" data-dismiss="modal"></button> */}
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: getUserData(state),
    }
}

const mapDispatchToProps = dispatch => ({

});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterLink);
