import React, { Component } from 'react';
import apicaller from '../../util/ApiCaller';
import { connect } from "react-redux";
import { logoutUser } from "../../modules/actions";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import swal from 'sweetalert';

class SignUp extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            smsSentStatus: '',
            isSendingOtp: false,
            isVerifyingOtp: false
        }
    }

    componentDidMount() {
        this.props.logoutUser()
    }

    handleOnChangeFields = (userField, value) => {
        let user = this.state.user;
        user[userField] = value
        this.setState({
            user,
        })
    }

    validateUserFields(input) {
        let output = {};
        output.isValid = true;
        output.message = "";
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const rp = /^[0-9]{10}$/g;
        const rpass = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,16}$/
        if (!input.first_name || input.first_name == "") {
            output.isValid = false;
            output.message = "Please enter first name";
            return output;
        }
        if (!input.email || input.email == "" || !re.test(String(input.email).toLowerCase())) {
            output.isValid = false;
            output.message = "Please enter a valid email";
            return output;
        }
        if (!input.phone || input.phone == "" || !rp.test(input.phone)) {
            output.isValid = false;
            output.message = "Please enter a valid phone number";
            return output;
        }
        if (!input.password || input.password == "" || !rpass.test(input.password)) {
            output.isValid = false;
            output.message = "Please check for minimum conditions and enter a password.";
            return output;
        }
        if (input.password != input.confirm_password) {
            output.isValid = false;
            output.message = "Password confirmation does not matched";
            return output;
        }
        return output;
    }

    handleOnSignup = () => {
        let user = this.state.user;
        let validation = this.validateUserFields(user);
        if (validation.isValid) {
            this.sendOtpToUser(user.phone);
        }
        else {
            swal(validation.message, '', 'warning');
        }
    }

    sendOtpToUser = (phone) => {
        this.setState({
            isSendingOtp: true
        })
        apicaller('post', 'user/send/otp', { phone })
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.isUserExist) {
                        swal("User Exists", "User with same phone or email already exists in the system", "warning");
                    }
                    else {
                        // if (res.data.data.smsSentStatus.status == "success") {
                        //     this.setState({
                        //         smsSentStatus: res.data.data.smsSentStatus.status
                        //     })
                        // }
                        document.getElementById('model-button').click();
                        // else {
                        //     if (res.data.data.smsSentStatus.errors && res.data.data.smsSentStatus.errors[0].code == 51) {
                        //         swal("Please enter a valid number", "and try again!", "warning");
                        //     }
                        //     else {
                        //         swal("Something went wrong", "Please try again later!", "warning");
                        //     }
                        // }
                    }
                }
                this.setState({
                    isSendingOtp: false
                })
            })
            .catch(err => {
                console.log(err);
                swal('Error', 'something went wrong.', 'error');
                this.setState({
                    isSendingOtp: false
                })
            })
    }

    handleOnOtpSubmit = () => {
        console.log('hello');
        let otp = this.state.user.otp;
        const rotp = /^[0-9]{4}$/g;
        if (otp && otp != "" && rotp.test(otp)) {
            this.verifyOtp(this.state.user.phone, otp);
        }
        else {
            swal('Alert', 'please enter a valid otp', 'warning');
        }
    }

    verifyOtp = (phone, otp) => {
        this.setState({
            isVerifyingOtp: true
        })
        apicaller('post', 'user/verify/otp', { phone, otp })
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.otp_record.isVerified) {
                        this.createCustomer();
                    }
                    else {
                        swal('Alert', 'OTP validation failed! please try again.', 'warning')
                    }
                }
                this.setState({
                    isVerifyingOtp: true
                })
            })
            .catch(err => {
                swal('Error', 'something went wrong.', 'error');
                this.setState({
                    isVerifyingOtp: true
                })
            })
    }

    createCustomer = () => {
        let user = this.state.user;
        let data = new FormData();
        data.append("first_name", user.first_name);
        data.append("last_name", user.last_name || "");
        data.append("email", user.email.toLowerCase());
        data.append("phone", user.phone);
        data.append("password", user.password);
        data.append("isActive", true);
        apicaller('post', 'customer/register', data)
            .then(res => {
                if (res.status == 200) {
                    if (res.data.data.user) {
                        this.props.history.push('/customer/login');
                    }
                    else {
                        swal('user creation failed')
                    }
                }
            })
            .catch(err => {
                swal('Error', 'something went wrong.', 'error');
            })
    }

    handleShowPasswordConditions = () => {
        let ele = document.getElementById('password');
        ele.style.display = 'block'
    }
    handleHidePasswordConditions = () => {
        let ele = document.getElementById('password');
        ele.style.display = 'none'
    }

    render() {
        return (
            <div>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {/* Theme tittle */}
                <title>Ek Rupay</title>
                {/* Theme style CSS */}
                <link href="css/style.css" rel="stylesheet" />
                <link href="./vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
                <link rel="stylesheet" href="./vendor/chartist/css/chartist.min.css" />
                {/* Vectormap */}
                <link href="./vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
                <link href="./vendor/bootstrap-select/dist/css/bootstrap-select.min.css" rel="stylesheet" />
                <link href="./css/style.css" rel="stylesheet" />
                <link href="https://cdn.lineicons.com/2.0/LineIcons.css" rel="stylesheet" />
                <link href="./vendor/owl-carousel/owl.carousel.css" rel="stylesheet" />
                <div className="authincation mt-6 mb-5 h-100">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6">
                                <h1 className="text-center">EKRUPAY</h1>
                                <h3 className="text-center mb-4" style={{ color: 'black' }}>Customer Sign up</h3>
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                {/* <h4 className="text-center mb-4 text-white">Customer Sign up</h4> */}
                                                <form onSubmit={(e) => { e.preventDefault(); this.handleOnSignup() }}>
                                                    <div className="row">
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="mb-1 text-white"><strong>First Name</strong></label>
                                                                <input type="text" name="first_name" className="form-control" value={this.state.user.first_name} placeholder="First Name" onChange={(e) => this.handleOnChangeFields("first_name", e.target.value)} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <div className="form-group">
                                                                <label className="mb-1 text-white"><strong>Last Name</strong></label>
                                                                <input type="text" name="last_name" className="form-control" onChange={(e) => this.handleOnChangeFields("last_name", e.target.value)} placeholder="Last Name" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-2">
                                                            <div className="form-group">
                                                                <label className="mb-1 text-white"><strong>Email</strong></label>
                                                                <input type="email" name="email" className="form-control" onChange={(e) => this.handleOnChangeFields("email", e.target.value)} placeholder="Enter Email ID" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-2">
                                                            <div className="form-group">
                                                                <label className="mb-1 text-white"><strong>Phone Number</strong></label>
                                                                <input type="text" name="phone" className="form-control" onChange={(e) => this.handleOnChangeFields("phone", e.target.value)} placeholder="Enter Phone Number" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-2">
                                                            <div className="form-group">
                                                                <label className="mb-1 text-white"><strong>Password</strong></label>
                                                                <input type="password" name="password" className="form-control" onBlur={this.handleHidePasswordConditions} onFocus={this.handleShowPasswordConditions} onChange={(e) => this.handleOnChangeFields("password", e.target.value)} placeholder="Enter Password" required />
                                                            </div>
                                                            <div id="password" style={{ display: 'none' }}>
                                                                <small className="text-info">Password should contain</small><br />
                                                                <small>Atleast one Uppercase letter</small><br />
                                                                <small>Atleast one Special character letter</small><br />
                                                                <small>Atleast one Number letter</small><br />
                                                                <small>Minimum length of 8 and Maximum of 16</small><br />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-2">
                                                            <div className="form-group">
                                                                <label className="mb-1 text-white"><strong>Confirm Password</strong></label>
                                                                <input type="password" name="confirm_password" className="form-control" onChange={(e) => this.handleOnChangeFields("confirm_password", e.target.value)} placeholder="Confirm Password" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn bg-white text-primary btn-block" disabled={this.state.isSendingOtp}>{this.state.isSendingOtp ? "Sending" : "Send OTP"}</button>
                                                    </div>
                                                </form>
                                                <div className="new-account mt-3">
                                                    <p className="text-white">Already have an account? <a className="text-white" href="/customer/login">Sign in</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="d-none" id="model-button" data-toggle="modal" data-target="#exampleModalCenter"></button>


                {/* Modal */}
                <div className="modal fade" id="exampleModalCenter">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-group">
                                            <label className="text-label"><strong>OTP</strong></label>
                                            <input type="text" name="phone" className="form-control" onChange={(e) => this.handleOnChangeFields("otp", e.target.value)} placeholder="Enter OTP" required />
                                            {this.state.smsSentStatus == "success" ? <small className="text-success">OTP sent to your phone number</small> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" onClick={this.handleOnOtpSubmit} disabled={this.state.isVerifyingOtp} className="btn btn-primary btn-block">Verify and Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SignUp);